/**
* webmotor
*/
/* variables */
/**
* 3. ШРИФТЫ
* -----------------------------------------------------------------------------
*/
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* 4. ТИПОГРАФИКА
* -----------------------------------------------------------------------------
*/
/**
* 5. КОМПОНЕНТЫ (кнопки, хлебные крошки, пагинация, js-компоненты (слайдер, спойлер))
* -----------------------------------------------------------------------------
*/
/**
* общие стили сайта, сюда входит:
* 1. стили раскладки страницы (sticky footer)
* 2. настройки контейнеров
* 3. и разные общие стили
*/
html {
  height: 100%;
  box-sizing: border-box;
}
html.popup {
  overflow: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  min-width: 320px;
  font-family: "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 300;
  min-height: 100%;
  background: url(../img/fon.jpg) 50% 0 no-repeat;
  background-attachment: fixed;
  color: #6e6b6b;
}
.main-content {
  flex-grow: 1;
  margin-bottom: 50px;
}
/**
* Основной контейнер
*/
.container-wide {
  width: 100%;
  max-width: 1220px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
.container {
  width: 100%;
  max-width: 1024px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
}
address {
  font-style: normal;
}
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content .mfp-close {
  opacity: 1;
}
.mfp-content .mfp-close .icon {
  width: 25px;
  height: 25px;
  fill: #2a0404;
}
/**
* это иконки из svg-sprite
* указываем дефолтную ширину, высоту(для ие10)
*/
.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  fill: #fff;
}
/**
* footer webmotor
*/
.webmotor span {
  color: #34081e;
  font-size: 13px;
  font-weight: 300;
}
.webmotor a {
  color: #34081e;
  text-decoration: underline;
  transition: 0.15s color ease-in;
  font-size: 13px;
  font-weight: 300;
}
.page-head {
  margin-bottom: 20px;
}
p b {
  font-weight: 500;
  color: #1b191e;
}
p {
  max-width: 1060px;
}
/**
* Это основной стилевой файл
*/
/*10 08 2017 xs*/
/*header*/
.header {
  background: #3e90dc;
}
.header-logo-wrapper {
  height: 96px;
  min-width: 352px;
  overflow: hidden;
  position: relative;
  top: 6px;
  width: 246px;
}
.header-logo__image {
  display: block;
  height: auto;
  margin-bottom: 15px;
  max-height: 100%;
  max-width: 345px;
  padding-left: 16px;
  padding-top: 7px;
  width: auto;
}
.header-nav-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.header-nav-item {
  flex-grow: 1;
  border-right: 1px solid #a4c4e8;
  border-top: 1px solid #a4c4e8;
}
.header-nav-item:last-child {
  border-right: 0;
}
.header-nav-link {
  display: block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  transition: background 0.5s;
}
.header-nav-link:hover {
  background: #207ace;
}
.top-menu-wrap {
  background: #1f4c82;
}
.top-menu {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
}
.top-menu-item {
  margin-right: 1px;
}
.top-menu-link {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: block;
  padding: 8px 14px;
  text-decoration: none;
  position: relative;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.top-menu-link:before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 14px;
  background: #fff;
  top: 13px;
  right: 0;
}
.top-menu-link:hover {
  opacity: 1;
}
.top-menu .top-menu-item:last-child .top-menu-link::before {
  display: none;
}
.icons-wrap {
  display: flex;
  align-content: flex-end;
  max-height: 40px;
  padding-left: 27px;
}
.icons-wrap .icon-link {
  flex-grow: 1;
  display: block;
  border-right: 2px solid #1f4c82;
  background: #0e3360;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  text-align: center;
}
.icons-wrap .icon-link:hover {
  background: #3e90dc;
}
.icons-wrap .icon-link:last-child {
  border: 0;
}
.icons-wrap .icon {
  max-width: 25px;
  max-height: 25px;
  margin: auto;
}
.footer-bottom-wrap .icons-wrap {
  max-height: inherit;
}
.footer-bottom-wrap .icons-wrap .icon-link {
  width: 40px;
  height: 40px;
  padding: 0px;
  background: #fff !important;
  margin-right: 2px;
  margin-bottom: 2px;
}
.footer-bottom-wrap .icons-wrap .icon-link .icon {
  width: 24px;
  height: 24px;
}
.font-size-wrap .icon-link {
  display: block;
  transition: background 0.3s;
  width: 40px;
  height: 40px;
}
.font-size-wrap .icon-link:hover {
  background: #3e90dc;
}
.font-size-wrap-footer .icon-link {
  position: relative;
  top: 5px;
}
.lang-wrap {
  display: flex;
}
.lang-wrap .lang-link {
  font-size: 16px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  line-height: 39px;
  width: 40px;
  font-weight: 400;
  text-decoration: none;
}
.lang-wrap .lang-link:first-child {
  position: relative;
  padding-right: 5px;
}
.lang-wrap .lang-link:first-child:before {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: 18px;
  background: #fff;
  top: 10px;
  right: 7px;
}
.lang-wrap .lang-link:hover {
  font-weight: 600;
}
.test {
  background: url(../img/tmp/img02.jpg) -25px 0 no-repeat;
  height: 300px;
}
.buy-a-ticket-wrap {
  display: flex;
  align-items: center;
}
.buy-a-ticket-wrap .btn {
  font-size: 18px;
}
.quality-hand-wrap {
  display: flex;
  align-items: center;
}
.quality-hand-wrap .icon-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  line-height: 18px;
}
.quality-hand-wrap .icon-link .quality-hand {
  width: 70px;
  height: 51px;
  border: 2px solid #fff;
  fill: #fff;
  border-radius: 4px;
  margin-right: 20px;
  background: #3e90dc;
  transition: background 0.3s;
  padding: 5px;
}
.quality-hand-wrap .icon-link:hover .quality-hand {
  background: #1f4c82;
}
.main-content .container-wide .content.white-fon {
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 15px 26px 15px 26px;
}
/*footer*/
.footer .container-wide .footer-fon {
  background: #f1eee9;
  position: relative;
}
.footer hr {
  margin: 19px 10px 0;
  background: #c7c7c7;
  height: 1px;
  border: 0;
}
/*footer menu*/
.footer-menu {
  display: flex;
  flex-wrap: nowrap;
}
.footer-menu-item {
  flex-grow: 1;
  display: block;
  padding: 22px 10px 0 10px;
  flex-basis: auto;
}
.footer-menu-link {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
}
/*footer sub menu*/
.footer-sub-menu-item {
  margin-bottom: 4px;
}
.footer-sub-menu-link {
  color: #6e6b6b;
  font-size: 16px;
  font-weight: 300;
}
.footer-bottom-wrap .btn--primary {
  white-space: nowrap;
  margin-bottom: 12px;
  width: 100%;
}
.footer-bottom-wrap {
  padding: 15px 10px;
}
.footer-bottom-wrap .quality-hand-wrap {
  min-width: 190px;
}
.footer-bottom-wrap .quality-hand-wrap .icon-link .quality-hand {
  width: 76px;
  margin-right: 5px;
  background: transparent;
  fill: #c1bdb7;
  border-color: #c1bdb7;
}
.footer-bottom-wrap .quality-hand-wrap .icon-link {
  color: #000;
  font-size: 12px;
}
.totop-wrap {
  position: absolute;
  right: 40px;
}
.totop {
  background: #b1356c;
  width: 40px;
  height: 40px;
  display: block;
  padding: 5px;
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  z-index: 1;
}
.totop .icon {
  transform: rotate(-90deg);
  width: 30px;
  height: 30px;
}
.contacts-block-wrap {
  display: flex;
  justify-content: center;
  justify-content: space-around;
}
.contacts-block .title {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  text-transform: none;
}
.contacts-block .phone {
  font-size: 16px;
  font-weight: 400;
  color: #34081e;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
.contacts-block .phone a {
  color: #34081e;
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
}
.contacts-block .phone_divider {
  margin-left: 12px;
  margin-right: 12px;
}
.contacts-block .address {
  font-size: 20px;
  font-weight: 400;
  color: #34081e;
  text-transform: uppercase;
  margin: 0;
}
.footer-bottom-wrap .font-size-wrap .font {
  fill: #6e6b6b;
  width: 40px;
  height: 40px;
  transition: fill 0.3s;
}
.footer-bottom-wrap .font-size-wrap .font:hover {
  fill: #fff;
}
.footer-bottom-wrap .icons-wrap {
  align-content: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
  padding-top: 4px;
  margin-bottom: 26px;
}
.footer-bottom-wrap .icons-wrap .icon-link {
  background: transparent;
  border: 0;
}
.footer-bottom-wrap .icons-wrap .icon-link .icon {
  max-width: 45px;
  max-height: 45px;
  fill: #6e6b6b;
  transition: fill 0.5s;
  background: #fff;
}
.footer-bottom-wrap .icons-wrap .icon-link:hover .icon {
  fill: #1f4c82;
}
.copyright {
  font-size: 13px;
  color: #34081e;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 25px;
}
.btn--primary.whith-ico {
  display: flex;
  align-items: center;
  padding: 4px;
  padding-left: 8px;
}
.btn--primary.whith-ico .icon {
  fill: #3e90dc;
  width: 29px;
  height: 37px;
}
.btn--primary.whith-ico span {
  padding-left: 10px;
}
.news-and-photo-wrap {
  margin-bottom: 30px;
}
.content-block {
  background: #fff;
}
.content-block .content-block-title {
  border-bottom: 3px solid #b1356c;
  min-height: 62px;
  padding: 11px 10px;
  display: flex;
  justify-content: space-between;
}
.content-block .content-block-title .h1 {
  margin-bottom: 0;
}
.content-block .content-block-title .content-block-title-link {
  color: #6e6b6b;
  padding: 6px 23px  0 0;
  font-weight: 300;
}
.content-block .content-block-text {
  padding-right: 10px;
}
.main-news-wrap {
  padding-right: 0px;
}
.main-news__image-wrap {
  display: flex;
  flex-direction: row;
  min-height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
}
.main-news__tag-wrap {
  padding: 6px 12px 12px 10px;
  max-height: 40px;
  background: #b1356c;
}
.main-news__tag-wrap .h3 {
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
}
.main-news__text-wrap {
  padding: 18px 10px 20px 20px;
  line-height: 1.4;
  height: 230px;
  border: 1px solid #e2e2e2;
}
.main-news__text-wrap p {
  line-height: 1.4;
}
.main-news__text-wrap .news-title {
  font-size: 18px;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
  margin-bottom: 7px;
  display: block;
}
.news-wrap {
  background: #f4f4f4;
  padding: 0;
}
.news-one {
  display: flex;
  border-bottom: 2px solid #e2e2e2;
  height: 230px;
}
.news-one .news-one__left-col {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 19px;
}
.news-one time {
  display: block;
  background: #fff;
  text-align: center;
  width: 80px;
  height: 80px;
}
.news-one time .day {
  font-size: 48px;
  color: #1b191e;
  font-weight: 500;
  display: block;
}
.news-one time .month {
  position: relative;
  top: -20px;
  color: #1b191e;
  font-size: 14px;
  display: block;
}
.news-one .news-one__right-col {
  padding-top: 26px;
  padding-right: 20px;
  line-height: 1.4;
}
.news-one .news-one__right-col p {
  line-height: 1.4;
}
.news-one .news-title {
  font-size: 18px;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
  margin-bottom: 7px;
  display: block;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  .main-news__image-wrap {
    min-height: 300px;
  }
  .main-news__text-wrap {
    height: 300px;
  }
  .news-one {
    height: 300px;
  }
}
.block-photo .content-block {
  display: flex;
  flex-direction: column;
}
.block-photo .content-block-text {
  height: 100%;
  padding: 0;
}
.block-photo .photo {
  flex-grow: 1;
}
.block-photo img {
  min-height: 100%;
  width: 100%;
  margin-bottom: 0;
}
.photo {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.partners-block {
  margin-top: 30px;
}
.partners-wrap {
  display: flex;
}
.partners-wrap .partner {
  padding: 30px;
  border-right: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
}
.partners-wrap .partner:last-child {
  border-right: 0;
}
.promo {
  min-height: 527px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.main-show-wrap {
  padding-left: 10px;
  padding-right: 0;
  display: flex;
}
.main-show-wrap__main-show-img-wrap {
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.main-show-wrap__main-show-info {
  background-color: rgba(11, 10, 16, 0.7);
  width: 100%;
  color: #fff;
  padding: 10px;
}
.main-show-wrap__main-show-info .h1 {
  color: #fff;
  font-size: 36px;
  margin-bottom: 10px;
}
.main-show-wrap__main-show-info p {
  color: #fff;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}
.main-show-wrap__main-show-info a {
  color: #fff;
  font-size: 16px;
}
.main-show-wrap__main-show-info .age {
  margin-left: 20px;
}
.age {
  display: inline-block;
  background-color: #fff;
  font-size: 16px;
  color: #000;
  padding: 4px 12px;
  font-family: "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 400;
  position: relative;
  top: -5px;
}
.calendar-container {
  padding: 0;
  padding-right: 10px;
  display: flex;
}
.calendar-wrap {
  background-color: #f4f4f4;
  width: 100%;
}
.show-wrap {
  display: flex;
  padding: 6px;
  max-height: 111px;
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 10px;
}
.show-wrap:last-child {
  border-width: 0;
}
.show-name {
  width: 80%;
}
.show-name a {
  font-size: 18px;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
  display: block;
  margin-bottom: 0px;
}
.show-name .time {
  background: transparent none repeat scroll 0 0;
  color: #1b191e;
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  height: auto;
  margin-bottom: -8px;
  width: auto;
}
.show-name .place {
  color: #1b191e;
}
.show-time {
  width: 20%;
}
.show-time .age {
  top: -2px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 22px;
}
.show-time .btn {
  font-size: 12px;
  font-weight: 400;
  padding: 8px 0;
  white-space: normal;
}
.show-time .btn.btn--disabled {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  padding: 0;
}
.submenu-wrap {
  background-color: #b1356c;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .submenu-wrap {
    display: none;
  }
}
.submenu-list {
  display: inline-flex;
}
.submenu-link {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 20px;
  display: block;
  font-weight: 400;
  transition: color 0.2s;
  font-family: "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
  white-space: nowrap;
  font-family: "Arial Narrow", Arial;
}
.submenu-item {
  background-color: #b1356c;
  transition: background-color 0.4s;
  border-right: 1px solid #fff;
  font-family: "Arial Narrow", Arial;
}
.submenu-item.select {
  background-color: #fff;
}
.submenu-item.select .submenu-link {
  color: #b1356c;
}
.submenu-item:hover {
  background-color: #fff;
}
.submenu-item:hover .submenu-link {
  color: #b1356c;
}
.submenu-list-for-kids {
  align-content: stretch;
  flex-wrap: wrap;
}
.download-poster {
  display: block;
  background-color: #fff;
  color: #b1356c;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  font-family: "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
  height: 36px;
  line-height: 36px;
  margin-bottom: 7px;
  margin-right: 10px;
  margin-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.download-poster:hover {
  text-decoration: underline;
}
.mobile-inline-menu-list-wrap {
  display: none;
}
.desktop-inline-menu-list-wrap {
  display: block;
}
.inline-menu-wrap {
  border-top: 4px solid #b1356c;
  padding-top: 4px;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
}
ul.inline-menu-list {
  display: flex;
  padding: 0;
  margin: 0;
  margin-bottom: 4px;
}
ul.inline-menu-list li.inline-menu-item {
  padding-left: 0;
}
ul.inline-menu-list li.inline-menu-item::before {
  display: none;
}
a.inline-menu-link {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: #670c34;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  text-decoration: none;
  background: #fff;
  transition: background 0.5s ease 0s, color 0.3s;
  margin-right: 5px;
}
a.inline-menu-link:hover {
  background-color: #b1356c;
  color: #fff;
}
li.inline-menu-item.select a.inline-menu-link {
  background-color: #b1356c;
  color: #fff;
}
.phone-numbers-wrap {
  float: right;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  top: -8px;
}
.phone-numbers-wrap a {
  color: #2a0404;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}
.affiche-date-wrap {
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #e2e2e2;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.show-one-wrap {
  border-bottom: 1px solid #b1356c;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}
.show-one-wrap:last-child {
  border-bottom: 0px;
}
.show-one__time-img {
  display: flex;
}
.show-one__time {
  display: block;
  background: #fff;
  text-align: center;
  width: 104px;
  height: 104px;
  display: flex;
  flex-direction: column;
}
.show-one__time .day {
  font-size: 48px;
  color: #1b191e;
  font-weight: 500;
  margin-bottom: -2px;
}
.show-one__time .month {
  position: relative;
  top: -15px;
  color: #1b191e;
  font-size: 14px;
  margin-bottom: -14px;
}
.show-one__time .weekday {
  font-size: 14px;
  font-weight: 500;
  color: #1b191e;
}
.show-one-first-col {
  display: flex;
  padding-left: 11px;
}
.show-one-wrap .show-one__time {
  margin-right: 15px;
}
.show-one-wrap .show-one__img {
  width: 104px;
  height: 104px;
  margin-right: 20px;
}
.show-one__name {
  font-size: 18px;
  display: block;
  margin-right: 15px;
  display: inline-block;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
}
.show-one__about {
  max-width: 460px;
}
.show-one__about .age {
  display: inline-block;
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  top: 0;
  margin-bottom: 15px;
}
.show-one__about p {
  font-weight: 400;
  margin-bottom: 5px;
}
.show-one__about p span {
  color: #1b191e;
  font-weight: 500;
}
.start-time {
  font-size: 24px;
  font-weight: 600;
  color: #1b191e;
}
.holl {
  color: #1b191e;
  white-space: nowrap;
}
.holl:first-letter {
  text-transform: uppercase;
}
.buy-ticket {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}
.buy-ticket a {
  color: #3e90dc;
  text-align: right;
}
.buy-ticket a:hover {
  color: #3e90dc;
}
.affiche-show-wrap {
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #e2e2e2;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.show-one-second-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.affiche-show-wrap .time-wrap {
  border-bottom: 1px solid #b1356c;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.affiche-show-wrap .time-wrap:last-child {
  border: 0px;
}
.affiche-show-wrap .time-wrap .date {
  color: #1b191e;
  font-weight: 500;
}
.affiche-show-wrap .time-wrap .date span {
  font-weight: 300;
}
.affiche-show-wrap .time-wrap .time {
  color: #1b191e;
  font-weight: 500;
}
.inner-news-wrap {
  padding: 6px 0px;
}
.inner-news-wrap .inner-news-one-wrap {
  margin-bottom: 20px;
}
.inner-news-wrap .news-one {
  background: #f4f4f4;
  border: 1px solid #e2e2e2;
  height: 100%;
  min-height: 230px;
}
.pagination-wrapper {
  padding-top: 20px;
}
.main-partners-block {
  border-top: 3px solid #b1356c;
  margin-left: -8px;
  margin-right: -8px;
}
.main-partners-block .partner {
  display: flex;
  flex-direction: column;
}
.partner-img {
  width: 100%;
}
.partner-text .title {
  color: #1b191e;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.person-wrap {
  border-top: 1px solid #e2e2e2;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px;
}
.person-one {
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
  text-decoration: none !important;
}
.person-one:hover {
  background: #f4f4f4;
}
.person-img {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.person-text .title {
  text-decoration: underline;
  font-size: 18px;
}
.person-text p {
  color: #6e6b6b;
  text-decoration: none !important;
  font-style: italic;
}
.repertuar-show-wrap {
  border-top: 4px solid #b1356c;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
}
.repertuar-show-one {
  display: flex;
  justify-content: space-between;
  background: #f4f4f4;
  border: 1px solid #e2e2e2;
  margin-bottom: 7px;
  padding: 5px 10px;
}
.repertuar-show-one .age {
  width: 80px;
  height: 80px;
  font-size: 30px;
  font-weight: 600;
  line-height: 73px;
  position: relative;
  top: -5px;
  right: -10px;
}
.repertuar-show-one .repertuar-show-one__img {
  width: 260px;
  background: #e7e7e7;
  display: flex;
  justify-content: center;
  margin-right: 20px;
}
.repertuar-show-one .repertuar-show-one__img img {
  height: 100%;
  width: auto;
}
.repertuar-show-one__about {
  margin-right: auto;
}
.repertuar-show-one__about .show-one__name {
  margin-bottom: 15px;
  display: block;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
}
.repertuar-show-one__about p {
  margin-bottom: 10px;
  font-weight: 400;
}
.repertuar-show-one__about p span {
  color: #1b191e;
  font-weight: 500;
}
.repertuar-show-one__about .about {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "museocyrl", "bitter", Tahoma, Arial;
}
.news-one-wrap {
  margin-left: -8px;
  margin-right: -8px;
}
.news-one-wrap a {
  color: #6e6b6b;
}
.inner-news-right-col {
  padding-right: 10px;
}
.big-news-wrap .date {
  background: #b1356c;
  display: block;
  height: 80px;
  text-align: center;
  width: 80px;
  float: left;
  margin-right: 15px;
}
.big-news-wrap .date .day {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: -19px;
}
.big-news-wrap .date .month {
  color: #fff;
}
.big-news-wrap .img-wrap {
  margin-left: 96px;
}
.big-news-wrap .img-wrap img {
  max-width: 100%;
}
.inner-news-right-col .news-one {
  height: auto;
  margin-bottom: 20px;
}
.contacts-wrap {
  border-top: 4px solid #b1356c;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
}
.form-contacts-page {
  background-color: #f4f4f4;
  width: 100%;
  min-width: auto;
  margin-bottom: 0;
}
.form-contacts-page .site-form {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 16px;
}
.contacts-wrap .site-form__head {
  text-align: left;
  font-size: 24px !important;
  color: #2a0404 !important;
  margin-bottom: 19px;
}
.contacts-wrap .site-form__input {
  height: 40px;
}
.contacts-wrap .site-form-element-wrapper {
  margin-bottom: 24px;
}
.contacts-wrap .site-form-element-wrapper .site-form__input {
  border-color: #e2e2e2;
}
.contacts-wrap .site-form-element-wrapper:hover .site-form__label,
.contacts-wrap .site-form-element-wrapper:focus .site-form__label {
  color: #207ace;
}
.contacts-wrap .site-form-element-wrapper:hover .site-form__input,
.contacts-wrap .site-form-element-wrapper:focus .site-form__input {
  border-color: #207ace;
}
.contacts-wrap .site-form__input.error {
  background-color: #fff;
  border-color: #fd7676;
}
.contacts-wrap .site-form label.error {
  color: #d32d2d;
}
.contacts-wrap .site-form__textarea {
  height: 74px;
  min-height: inherit;
  border-color: #e2e2e2;
  font-size: 15px;
}
.contacts-wrap .site-form__checkbox-label {
  font-size: 16px;
  display: block;
}
.contacts-wrap .checkbox:not(checked) + label::before {
  border-color: #e2e2e2;
}
.contacts-wrap .checkbox + label::after {
  border-color: #3e90dc;
  border-style: solid;
  border-width: 0 2px 2px 0;
  background: transparent;
  transform: rotate(45deg);
  width: 8px;
  height: 12px;
  left: 6px;
  top: 3px;
  opacity: 0;
}
.contacts-wrap .checkbox:checked + label::after {
  border-color: #3e90dc;
  border-style: solid;
  border-width: 0 2px 2px 0;
  background: transparent;
  transform: rotate(45deg);
  width: 8px;
  height: 12px;
  left: 6px;
  top: 3px;
  opacity: 1;
}
.builder-description-preview {
  background-position: 50% 50%;
  background-repeat: repeat;
}
.popover-wrapper {
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 10px;
}
.popover-wrapper p {
  font-size: 14px;
  margin-bottom: 5px;
}
.popover-close {
  text-align: right;
  width: 96%;
  display: block;
  padding: 3px;
}
.popover-close .icon {
  fill: #000;
  width: 14px;
  height: 14px;
}
.contacts-info {
  margin-bottom: 20px;
}
.contacts-info p {
  margin-bottom: 10px;
}
.contacts-info p b {
  color: #1b191e;
  font-weight: 500;
}
.person-card__info {
  border-left: 1px solid #e2e2e2;
}
.person-card__info hr {
  margin-left: -10px;
  margin-right: -8px;
}
.person-card__photo {
  padding: 0 20px 0 40px;
}
.person-card__photo img {
  margin: 0 0 20px;
  display: block;
}
.person-card__photo .profession {
  font-weight: 500;
  color: #1b191e;
}
.person-card__photo .birthday b {
  font-weight: 500;
  color: #1b191e;
}
.photoalbum-wrap.row .photoalbum-cover {
  position: relative;
  padding: 10px;
}
.photoalbum-wrap.row .photoalbum-cover img {
  display: block;
}
.photoalbum-wrap.row .photoalbum-cover .photoalbum-cover-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: rgba(11, 10, 16, 0.7);
  color: #fff;
  padding: 10px;
  transition: background-color 0.5s;
  text-decoration: underline;
}
.photoalbum-wrap.row .photoalbum-cover .photoalbum-cover-title:hover {
  background-color: rgba(11, 10, 16, 0.9);
  text-decoration: none;
}
.afisha-show-card-wrap {
  border-top: 4px solid #b1356c;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
}
.afisha-show-card-wrap-col-right {
  border-left: 1px solid #e2e2e2;
  padding-left: 20px;
}
.afisha-show-card-wrap-col-right hr {
  margin: 40px -18px 40px -20px;
}
.show-card-all-description-wrap {
  margin-bottom: 20px;
}
.description-wrap {
  margin-bottom: 10px;
}
.description-col-01 {
  padding-right: 0;
}
.description-col-02 {
  font-family: "museocyrl", "bitter", Tahoma, Arial;
  padding-right: 0;
}
.description-person-status {
  font-size: 14px;
  font-style: italic;
}
.press a {
  display: block;
}
.press p {
  font-size: 14px;
}
.review {
  font-size: 14px;
}
.review p {
  font-size: 14px;
}
.review .review-title {
  color: #1b191e;
  font-weight: 500;
  margin-bottom: 10px;
}
.in-the-poster-wrap {
  color: #1b191e;
  font-weight: 300;
}
.in-the-poster-wrap b {
  font-weight: 500;
}
.in-the-poster-wrap .row {
  margin-bottom: 10px;
}
.in-the-poster-wrap .in-the-poster-col-03 {
  text-align: right;
}
.afisha-show-card-about-start {
  background: #f4f4f4;
  border: 1px solid #e2e2e2;
  padding: 15px;
  margin-bottom: 20px;
}
.afisha-show-card-about-start p {
  margin-bottom: 5px;
  font-size: 300;
}
.afisha-show-card-about-start p b {
  color: #1b191e;
  font-size: 500;
}
.afisha-show-card-about-start .age {
  top: 0;
  font-size: 16.2px;
}
/*slider-count*/
.slider-count {
  margin-bottom: 20px;
}
.nav-slider-count {
  margin-bottom: 20px;
}
.nav-slider-count button {
  border: 0px;
}
.nav-slider-count .slick-list {
  margin-left: -10px;
  margin-right: -5px;
}
.nav-slider-count-item {
  position: relative;
  width: 138px;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
.nav-slider-count-item-image-inner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nav-slider-count-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  font-family: 'object-fit: cover;';
}
.nav-slider-count-item-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  transition: 0.15s background-color ease;
}
.nav-slider-count-item-background:hover {
  background-color: rgba(0, 47, 73, 0.6);
}
.nav-slider-count .slick-current .nav-slider-count-item-background {
  background-color: rgba(0, 47, 73, 0.6);
  cursor: default;
}
.slider-count__image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 650px;
  margin-left: auto;
  margin-right: auto;
}
.slider-count__btn-prev {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.slider-count__btn-prev:focus {
  outline: 0;
}
.slider-count__btn-prev .icon {
  width: 20px;
  height: 23px;
  fill: #670c34;
  transition: 0.15s fill ease;
  transform: rotateZ(180deg);
}
.slider-count__btn-prev .icon:hover {
  fill: #b1356c;
}
.slider-count__btn-next {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.slider-count__btn-next:focus {
  outline: 0;
}
.slider-count__btn-next .icon {
  width: 20px;
  height: 23px;
  fill: #670c34;
}
.slider-count__btn-next .icon:hover {
  fill: #b1356c;
}
.slider-count-nav-wrapper {
  text-align: center;
}
.slider-count-nav-inner-wrapper {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: none;
}
.slider-count-length {
  padding-left: 15px;
  padding-right: 15px;
  color: #6e6b6b;
}
.slider-count-wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.slider-count {
  max-width: 100%;
  margin-bottom: 15px;
}
/*header-mobile-menu*/
.header-mobile-menu-button-wrapper {
  display: block;
  height: 64px;
}
.burger-wrap {
  display: flex;
  align-items: center;
}
.header__mobile-menu-button {
  background-color: #207ace;
  border: 0 none;
  display: block;
  height: 40px;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 40px;
  border-radius: 5px;
  border: 2px solid #207ace;
}
.header__mobile-menu-button:focus {
  outline: 0;
}
.header__mobile-menu-button div {
  background: #fff none repeat scroll 0 0;
  height: 2px;
  left: 50%;
  margin-left: -12px;
  margin-top: 6px;
  position: absolute;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  width: 23px;
}
.header__mobile-menu-button div:nth-child(1) {
  top: 0;
}
.header__mobile-menu-button div:nth-child(2) {
  top: 11px;
}
.header__mobile-menu-button div:nth-child(3) {
  top: 21px;
}
.header__mobile-menu-button.active div:nth-child(1) {
  top: 10px;
  transform: rotate(45deg);
  transition: all 0.3s ease 0s;
}
.header__mobile-menu-button.active div:nth-child(2) {
  opacity: 0;
  transition: opacity 0.3s ease 0s;
}
.header__mobile-menu-button.active div:nth-child(3) {
  top: 10px;
  transform: rotate(-45deg);
  transition: all 0.3s ease 0s;
}
#header-mobile-menu {
  display: none;
}
.header-mobile-menu-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.header-mobile-menu-container .header-nav-item {
  border: 1px solid #a4c4e8;
}
.header-nav-list-mobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.top-menu-mobile {
  display: flex;
  flex-direction: column;
}
.mobile-menu .buy-a-ticket-wrap {
  align-items: center;
}
.mobile-menu .buy-a-ticket-wrap .btn--secondary {
  padding-left: 0;
  padding-right: 0;
  font-size: 13px;
  white-space: normal;
  padding-top: 4px;
  padding-bottom: 4px;
}
.mobile-menu .header-logo__image {
  width: 100%;
  padding-left: 0;
}
.mobile-menu .quality-hand-wrap {
  justify-content: flex-end;
}
.mobile-menu .quality-hand-wrap .icon-link {
  display: inline-block;
  line-height: 10px;
}
.mobile-menu .quality-hand-wrap .icon-link .quality-hand {
  width: 30px;
  height: 30px;
  margin-right: 0;
}
.mobile-menu .top-menu-mobile-wrap {
  background: #1f4c82;
}
.mobile-menu .top-menu-mobile {
  display: flex;
  margin-bottom: 10px;
}
.mobile-menu .top-menu-link {
  padding-top: 4px;
  padding-bottom: 4px;
}
.mobile-menu .top-menu-link:before {
  display: none;
}
.mobile-menu .phone-wrap {
  padding: 22px 0 2px 0;
}
.mobile-menu .phone-wrap a {
  color: #fff;
  font-size: 18px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 10px;
  text-decoration: none;
}
.mobile-menu .phone-wrap a:last-child {
  margin-right: 0;
}
.mobile-menu .icons-wrap {
  padding-right: 0;
}
.footer-bottom-wrap-desktop {
  display: flex;
}
.footer-bottom-wrap-mobile {
  display: none;
}
.footer-mobile-top-line {
  padding: 0 !important;
}
.footer-mobile-top-line .quality-hand-wrap .icon-link .quality-hand {
  width: 49px;
  height: 49px;
}
.footer-mobile-top-line .btn--primary {
  height: 49px;
  padding-top: 1px;
  padding-bottom: 0;
}
.footer-bottom-wrap-mobile .contacts-block-wrap {
  justify-content: flex-start;
  padding-bottom: 10px;
}
.footer-bottom-wrap-mobile .copyright {
  margin-bottom: 6px;
}
.footer-mobile-top-line > div {
  padding: 5px;
}
/*//header-mobile-menu*/
.mobile-menu {
  display: none;
}
.desktop-menu {
  display: block;
}
/*datepicker*/
.datepicker {
  width: 100%;
  border-radius: 0;
}
.datepicker--nav-title {
  font-size: 18px;
}
.datepicker--day-name {
  color: #cccccc;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
}
.datepicker--cell {
  color: #6a6a6a;
  border-radius: 0;
  height: 38px;
}
.datepicker--cell.-current- {
  color: #fff;
  background: #b1356c;
}
.datepicker--nav {
  border-bottom: 2px solid #ebe7e7;
}
.content.white-fon.fix-integration {
  padding: 0px 8px 15px 8px !important;
  border: none !important;
  margin-top: -16px !important;
}
.age {
  text-align: center;
}
.contacts-left-col {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}
@media screen and (max-width: 767px) {
  .contacts-left-col {
    flex-direction: column-reverse;
  }
}
.map {
  height: 100%;
}
.contacts-info-mobile {
  display: none;
}
.partners-wrap .partner {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
a.inline-menu-link {
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
}
/*datepicker*/
.datepicker {
  width: 100% !important;
  border-radius: 0 !important;
}
.datepicker--nav-title {
  font-size: 18px !important;
}
.datepicker--day-name {
  color: #cccccc !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}
.datepicker--cell {
  color: #6a6a6a !important;
  border-radius: 0 !important;
  height: 38px !important;
}
.datepicker--cell.-current- {
  color: inherit !important;
  background: #fff !important;
}
.datepicker--nav {
  border-bottom: 2px solid #ebe7e7 !important;
}
.datepicker--cell.-selected- {
  color: #fff !important;
  background: #b1356c !important;
}
.datepicker--cell.-disabled- {
  color: #ccc !important;
}
.datepicker--cell.-disabled-.-focus- {
  background: transparent !important;
}
.performance_annotation,
.performance_annotation p,
.performance_annotation span {
  font-family: "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6e6b6b !important;
  text-align: left !important;
}
.submenu-link {
  padding: 10px 5px;
  font-size: 16px;
  /*letter-spacing: -0.8px;*/
  font-family: "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
  align-items: center;
  display: flex;
}
.repertuar-show-one__about p {
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 1.2;
}
.repertuar-show-one__about {
  margin-right: auto;
  padding: 4px 0;
}
.content {
  color: #525252;
}
.repertuar-show-one__about p {
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 1.2;
}
.show-one__about p {
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 1.2;
}
/*additional xs 22 09 2017 */
.submenu-link {
  height: 100%;
  display: flex;
  align-items: center;
}
.phone-numbers-wrap {
  top: -2px;
}
.phone-numbers-wrap {
  font-weight: 300;
}
.main-content .container-wide .content.white-fon {
  padding: 15px 30px;
}
.show-one__time .day {
  font-weight: 600;
}
.start-time {
  font-weight: 600;
}
.show-one__time .weekday {
  font-weight: 600;
}
.show-one__time .month {
  color: #1b191e;
  font-size: 15px;
  margin-bottom: -4px;
  position: relative;
  top: -6px;
}
.affiche-show-wrap {
  background-color: transparent;
  border: 0;
}
.show-one-wrap {
  background: #f4f4f4 none repeat scroll 0 0;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
}
.show-one-wrap:last-child {
  border-bottom: 1px solid #e2e2e2;
}
.affiche-show-wrap .time-wrap .time {
  font-weight: 600;
}
.affiche-show-wrap .time-wrap .date {
  font-weight: 600;
}
.show-one__time .weekday {
  font-size: 15px;
}
.affiche-date-wrap {
  border: 0px;
  background-color: transparent;
}
.download-poster {
  font-weight: 500;
}
a.inline-menu-link {
  font-size: 16px;
  font-weight: 400;
}
.show-one__img img {
  max-width: 104px;
}
.breadcrumb a {
  font-weight: 400;
}
.breadcrumb {
  padding: 4px 0 8px 28px;
}
.repertuar-show-one {
  padding: 15px 10px;
}
.repertuar-show-one .age {
  top: -15px;
}
.contacts-info .h2 {
  margin-top: 30px;
}
.contacts-info .h2:first-child {
  margin-top: 0;
}
.site-form-wrapper.form-contacts-page {
  margin-top: 20px;
}
.checkbox:not(checked) + label::before {
  top: 1px;
}
.checkbox:not(checked) + label::after {
  top: 5px;
}
#personal-error {
  position: relative !important;
}
.content .site-form__button-submit,
.site-form__button-submit {
  padding-left: 62px;
  padding-right: 62px;
}
.footer-sub-menu-link:hover {
  text-decoration: none;
}
.news-one time .day {
  font-weight: 600;
}
.news-one time .month {
  top: -8px;
}
a.inline-menu-link {
  min-width: 97px;
  margin-bottom: 10px;
}
.desktop-inline-menu-list-wrap .inline-menu-list {
  margin-bottom: -2px;
}
ul.inline-menu-list {
  flex-wrap: wrap;
}
.big-news-wrap .date .day {
  font-weight: 600;
  margin-bottom: -14px;
}
.news-one__right-col .news-title {
  color: #670c34;
}
.news-one .news-one__left-col {
  padding-top: 27px;
}
.person-text p {
  font-size: 14px;
}
.person-text .title {
  font-family: "museocyrl";
  font-size: 18px;
  margin-bottom: 8px;
}
.person-one:hover .person-text .title {
  text-decoration: none;
}
.person-card__info {
  padding-right: 30px;
}
.spoiler-title-icon-wrapper .icon {
  fill: #670c34;
}
.spoiler__title {
  /*text-transform: lowercase;*/
  font-weight: 300;
}
.spoiler__title:first-letter {
  /*text-transform: uppercase;*/
}
.photoalbum-cover-title {
  /*text-transform: lowercase;*/
  font-weight: 300;
}
.photoalbum-cover-title:first-letter {
  /*text-transform: uppercase;*/
}
.top-menu {
  padding-top: 2px;
}
.top-menu-link::before {
  top: 11px;
}
.content.white-fon.fix-integration {
  padding: 0px 8px 15px 8px !important;
  border: none !important;
  margin-top: -16px !important;
}
.age {
  text-align: center;
}
.partners-wrap .partner {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
a.inline-menu-link {
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
}
/*datepicker*/
.datepicker {
  width: 100% !important;
  border-radius: 0 !important;
}
.datepicker--nav-title {
  font-size: 18px !important;
}
.datepicker--day-name {
  color: #cccccc !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}
.datepicker--cell {
  color: #6a6a6a !important;
  border-radius: 0 !important;
  height: 38px !important;
}
.datepicker--nav {
  border-bottom: 2px solid #ebe7e7 !important;
}
.datepicker--cell.-selected- {
  color: #fff !important;
  background: #b1356c !important;
}
.datepicker--cell.-disabled- {
  color: #ccc !important;
}
.datepicker--cell.-disabled-.-focus- {
  background: transparent !important;
}
.performance_annotation,
.performance_annotation p,
.performance_annotation span {
  font-family: "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6e6b6b !important;
  text-align: left !important;
}
.repertuar-show-wrap {
  padding-left: 0;
  padding-right: 0;
}
/**
* gallery page
*/
.gallery-page-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 85px;
}
.gallery-page-list-item {
  height: 300px;
  margin-bottom: 20px;
}
.gallery-page-list-item-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 27px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-color: rgba(0, 47, 73, 0.6);
  background-size: cover;
  text-decoration: none !important;
}
.gallery-page-list-item-inner-wrapper:hover .galley-page__head {
  color: #fff;
}
.gallery-page-list-item-inner-wrapper:hover .btn__gallery-page {
  background-color: #207ace;
  border-color: #207ace;
}
.galley-page__head {
  font-size: 23px;
  color: #fff;
  border-bottom-color: #fff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-decoration: none !important;
  transition: 0.15s color ease, 0.15s border-bottom-color ease;
}
.gallery-footer-wrapper {
  text-align: center;
}
.photo-wrap {
  margin-bottom: 20px;
}
.photo-one {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: stretch;
  margin-bottom: 20px;
}
.photo-one img {
  max-width: 100%;
}
.photo-one-link {
  background: #ccc;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.photo-wrap {
  max-width: 100%;
  max-height: 100%;
}
.hall td {
  min-width: 24px;
  text-align: center;
}
table.hall td.e {
  cursor: pointer;
}
.legenda {
  padding-top: 20px;
}
.colorplace {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
  top: 7px;
}
.online-check {
  padding-bottom: 20px;
  padding-top: 11px;
}
p.mesta_green span {
  background: #b8dea1;
}
p.mesta_gray span {
  background: #d4d4d4;
}
p.mesta_orange span {
  background: #f4aa6d;
}
table.hall td.d {
  background-color: #d4d4d4;
  color: #888888;
}
.kolvo_biletov {
  padding-top: 11px;
}
.form-contacts-page {
  position: relative;
}
.form-contacts-page .success {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  display: none;
}
/**
* файл для стилей для разных устройств
*/
@media (max-width: 1200px) {
  .icons-wrap {
    padding-left: 0;
  }
  .icons-wrap .icon {
    width: 35px;
  }
  .lang-wrap {
    padding: 0;
  }
  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  /*footer phone*/
  .contacts-block .phone_divider {
    display: none;
  }
  .contacts-block .phone a {
    display: block;
  }
}
@media (max-width: 1024px) {
  .icons-wrap {
    padding-left: 0;
  }
  .icons-wrap .icon {
    width: 35px;
  }
  .lang-wrap {
    padding: 0;
  }
  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  .footer-bottom-wrap .btn--primary {
    width: auto;
  }
  .in-the-poster-wrap .in-the-poster-col-03 {
    padding-right: 5px;
  }
  .in-the-poster-wrap .in-the-poster-col-03 .btn--primary,
  .in-the-poster-wrap .in-the-poster-col-03 .content .btn--primary {
    padding-right: 10px;
    padding-left: 10px;
  }
  .photoalbum-wrap.row .photoalbum-cover .photoalbum-cover-title {
    font-size: 13px;
  }
}
@media (max-width: 820px) {
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  .header-mobile-menu-button-wrapper {
    height: 108px;
  }
  .header-mobile-menu {
    height: auto;
    height: calc(100vh - 108px);
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background: #3e90dc;
  }
  .footer-menu {
    flex-wrap: wrap;
  }
  .footer-menu-item {
    width: 25%;
  }
  .header__mobile-menu-button {
    margin: 0 auto;
  }
  .header-logo-wrapper {
    display: block;
    margin: 0 auto;
  }
  .buy-a-ticket-wrap .btn {
    margin: 0 auto;
  }
  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  .footer-bottom-wrap-desktop {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .container-wide {
    padding-right: 0;
    padding-left: 0;
  }
  .row {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
    margin-left: 0;
  }
  .lang-wrap .lang-link {
    width: 30px;
    font-size: 13px;
  }
  .lang-wrap .lang-link:first-child:before {
    right: 6px;
  }
  .top-menu {
    display: inline-flex;
  }
  .top-menu-link {
    line-height: 15px;
  }
  .icons-wrap {
    display: inline-flex;
    align-items: center;
  }
  .icons-wrap .icon-link {
    text-align: center;
  }
  .icons-wrap .icon {
    width: 24px;
    padding-top: 9px;
  }
  .header-logo__image {
    width: 240px;
  }
  .header-nav-link {
    font-size: 15px;
  }
  .block-photo {
    min-height: 200px;
    margin-top: 30px;
  }
  .main-news-wrap {
    padding-left: 0;
  }
  .partners-block {
    margin-left: 10px;
    margin-right: 10px;
  }
  .container-wide .partners-block {
    margin-left: 0;
    margin-right: 0;
  }
  .footer-bottom-wrap .btn--primary {
    padding-left: auto;
    padding-right: auto;
    font-size: 13px;
  }
  .contacts-block .phone a {
    display: inline-block;
  }
  .contacts-block .phone span {
    display: none;
  }
  .contacts-block .address {
    font-size: 13px;
  }
  .icons-wrap .icon {
    padding-top: 0;
  }
  .map {
    height: 500px;
    margin-top: 20px;
  }
  .show-one-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .show-one-first-col {
    padding-left: 0;
  }
  .show-one__about .age {
    margin-left: 0;
    margin-bottom: 5px;
  }
  .show-one__name {
    margin-bottom: 5px;
  }
  .start-time {
    font-size: 16px;
  }
  .holl {
    white-space: normal;
  }
  .show-one-first-col {
    flex-direction: column;
  }
  .show-one__time-img {
    display: flex;
    margin-bottom: 20px;
  }
  .show-one-first-col-afisha-show {
    flex-direction: row;
  }
  .buy-ticket {
    align-items: flex-start;
    padding-left: 0;
    padding-bottom: 10px;
  }
  .show-one__about {
    padding-left: 10px;
  }
  .show-one-wrap .show-one__img {
    margin-right: 0;
  }
  .description-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .description-col-01 {
    padding-left: 0;
  }
  .in-the-poster-row {
    padding-left: 0;
    padding-right: 0;
  }
  .in-the-poster-col-01 {
    padding-left: 0;
  }
  .in-the-poster-col-02 {
    padding-left: 0;
  }
  .form-contacts-page .site-form {
    padding-right: 0;
    padding-left: 0;
  }
  .content-block .content-block-text {
    padding-right: 0;
    padding-left: 0;
  }
  .inner-news-right-col {
    padding-right: 0px;
  }
  .footer-menu-item {
    padding-right: 0;
    width: 50%;
  }
  .person-card__photo {
    padding-left: 0;
  }
  .person-page {
    align-content: stretch;
    flex-wrap: wrap;
  }
  .person-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .person-one:nth-child(3n+1) {
    border-left: 1px solid #e2e2e2;
  }
  .show-wrap {
    max-height: inherit;
  }
  .submenu-list {
    justify-content: center;
  }
  .submenu-item {
    flex-grow: 1;
  }
  .download-poster {
    margin-left: 5px;
  }
  .gallery-footer-wrapper .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-bottom-wrap .icons-wrap {
    margin-bottom: 12px;
  }
  .icons-wrap {
    max-height: inherit;
  }
  .icons-wrap .icon-link {
    padding: 10px;
    margin-bottom: 20px;
  }
  .main-content .container-wide .content.white-fon {
    padding: 15px 12px;
  }
}
@media (max-width: 610px) {
  .header-logo-wrapper {
    width: auto;
    max-width: 100%;
    min-width: inherit;
    height: auto;
  }
  .header-logo__image {
    max-width: 100%;
    width: 100%;
  }
  .header-mobile-menu-button-wrapper {
    height: 75px;
  }
  .header-logo-wrapper {
    height: 60px;
  }
  .header-mobile-menu {
    height: calc(100vh - 75px);
  }
}
@media (max-width: 414px) {
  .h1,
  h1,
  .content h1,
  .content .h1,
  .h2,
  h2,
  .content h2,
  .content .h2,
  .h3,
  h3,
  .content h3,
  .content .h3 {
    font-size: 27px;
    line-height: 1.5;
  }
  .footer-bottom-wrap-desktop {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: flex;
  }
  .footer-menu {
    flex-direction: column;
  }
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  .main-show-wrap {
    padding-left: 0;
  }
  .calendar-container {
    padding-right: 0;
  }
  .main-show-wrap__main-show-img-wrap {
    min-height: 350px;
  }
  .partners-wrap {
    padding: 10px;
  }
  .partners-wrap .partner {
    border: 1px solid #e2e2e2;
    margin-bottom: -1px;
    margin-left: -1px;
  }
  .partners-wrap .partner:last-child {
    border: 1px solid #e2e2e2;
  }
  /*footer*/
  .footer-bottom-wrap {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: block;
  }
  .footer-menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-menu-item-01 {
    order: 1;
  }
  .footer-menu-item-02 {
    order: 2;
  }
  .footer-menu-item-03 {
    order: 3;
  }
  .footer-menu-item-04 {
    order: 4;
  }
  .footer-menu-item-05 {
    order: 5;
  }
  .footer-menu-item-06 {
    order: 6;
  }
  .footer-menu-item-07 {
    order: 7;
  }
  .footer-menu-item-08 {
    order: 8;
  }
  .news-and-photo-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .promo {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .show-time .age {
    top: 6px;
  }
  .news-one {
    height: auto;
  }
  .main-news__text-wrap {
    height: auto;
  }
  .repertuar-show-one {
    flex-direction: column;
  }
  .repertuar-show-one .repertuar-show-one__img {
    width: 100%;
    margin-bottom: 10px;
  }
  .repertuar-show-one .age {
    width: 57px;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: 7px;
  }
  .afisha-show-card-wrap-col-right {
    border-left: 0px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    padding-left: 0px;
    padding-top: 20px;
    margin-top: 20px;
  }
  .breadcrumb {
    padding-left: 8px;
  }
  .phone-numbers-wrap {
    float: none;
  }
  .phone-numbers-wrap {
    font-size: 15px;
  }
  .submenu-list {
    flex-wrap: wrap;
  }
  .show-one-first-col-afisha-show {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  .buy-ticket {
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  .affiche-show-wrap .time-wrap:last-child {
    margin-bottom: 0;
  }
  .buy-ticket {
    align-items: center;
  }
  .download-poster {
    line-height: 16px;
    height: 42px;
    padding-top: 5px;
  }
  .site-form-wrapper {
    min-width: inherit;
  }
  ul.inline-menu-list {
    flex-wrap: wrap;
  }
  .person-wrap {
    padding-bottom: 10px;
  }
  .person-card__info {
    border-left: 0px solid #e2e2e2;
  }
  .show-one__time-img {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 14px;
  }
  .affiche-date-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .affiche-date-wrap .buy-ticket {
    border-top: 0 solid #ccc;
    padding-top: 0;
    align-items: flex-end;
  }
  .affiche-date-wrap .show-one-first-col {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-right: 0;
  }
  .show-one__time-img {
    padding-left: 10px;
  }
  .mobile-inline-menu-list-wrap {
    display: block;
    padding: 10px;
  }
  .mobile-inline-menu-list-wrap .row {
    padding-left: 0;
  }
  .mobile-inline-menu-list-wrap .select-wrap {
    padding-left: 0;
  }
  .desktop-inline-menu-list-wrap {
    display: none;
  }
  .select-wrap select {
    height: 30px;
    font-size: 14px;
  }
  .breadcrumb a {
    font-size: 13px;
  }
  .pagination a,
  .content .pagination a,
  .pagination span,
  .content .pagination span {
    line-height: 35px;
  }
  .pagination li,
  .content .pagination li {
    height: 35px;
    width: 35px;
  }
  .header-mobile-menu {
    height: calc(100vh - 55px);
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background: #3e90dc;
  }
  .footer-sub-menu-link {
    font-size: 13px;
  }
  .submenu-item {
    border-top: 1px solid #fff;
    margin-bottom: -1px;
  }
  .submenu-link {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .download-poster {
    margin-left: 5px;
  }
  .top-menu-mobile-wrap {
    padding-bottom: 10px;
  }
  .icons-wrap {
    max-width: 100%;
    flex-basis: 100%;
    align-items: flex-start;
    align-content: flex-start;
  }
  .icons-wrap .icon-link {
    flex-grow: 0;
    margin-right: 5px;
    padding: 8px;
  }
  .map-wrap {
    flex-basis: 100%;
    max-width: 100%;
    padding-top: 20px;
  }
  .map {
    height: calc(100vh - 200px) !important;
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
  }
  .contacts-left-col {
    order: 2;
  }
  .map-wrap {
    order: 1;
  }
  .contacts-info-mobile {
    display: block;
  }
  .contacts-info-desktop {
    display: none;
  }
}
@media (max-width: 370px) {
  .burger-wrap {
    padding: 0;
  }
}
