// ПЕРЕМЕННЫЕ
// Краткое содержание файла
// 1. типографика
// 2. компоненты
// --------------------------------------------------

// 1. ЦВЕТА
// --------------------------

@primary-color:           #2a0404;
@primary-color-2:         #b1356c; //темно-розовый


@header-color-1:         #3e90dc; // синий в шапке
@header-color-2:         #1f4c82; // темно-синий в шапке
@site-color-3:         #b1356c; // розовый в фоне заголовков
@site-color-4:         #f4f4f4; // серый в фоне заголовков


// gray scale
// основной серый цвет, цвет контролов
@gray:                    #e2e2e2;
// более темный цвет, для рамок контролов
@gray--darken:            #adadad;

@dark-font:               #1b191e;




//== Параметры сетки
//##
@screen-sm:                  768px;
@screen-md:                  1024px;
@screen-lg:                  1200px;
// !TODO создать ещё один брейкпоинт 1400px(1440px)
@screen-hg:                  1540px;

//** Отступ между колонок. Значение делится на половину слева и справа
@grid-gutter-width:         20px;



//== MASTER Z-INDEX
//
//## настройки z-index всех значимых блоков находятся здесь для наглядного редактирования
//##
@z-index-header-modal-form:                 3000;
@z-index-header--fixed:                     2000;
@footer-z-index:                            1900;



/**
* 3. ШРИФТЫ
* -----------------------------------------------------------------------------
*/
@main-font:                "robotocondensed", "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif;
@mono-font:                Menlo, Monaco, Consolas, "Courier New", monospace;
@title-font:               "museocyrl", "bitter", Tahoma, Arial;

.rouble {
	font-family:'rouble';
	font-weight: normal;
	font-style: normal;
}


// Font sizes
// --------------------------

@font-size: 16px;
@line-height: 1.38;



/**
* 4. ТИПОГРАФИКА
* -----------------------------------------------------------------------------
*/
@text-color:                   #6e6b6b; // цвет текстов по умолчанию

//== ОСНОВНОЙ текст, тег <p>
//
// ##
@paragraph-margin-top:         0;
@paragraph-margin-bottom:      20px;
@paragraph-lead-font-size:     @font-size * 1.5;

//== ЗАГОЛОВКИ <h1 - h6>
//
//## Основные стили заголовков
//##
@heading-color:             #2a0404; // цвета заголовков

@heading-margin-top:        0;
@heading-margin-bottom:     20px;

@heading-h1-font-size:      36px; // размеры заголовков
@heading-h2-font-size:      24px;
@heading-h3-font-size:      20px;
@heading-h4-font-size:      18px;
@heading-h5-font-size:      16px;
@heading-h6-font-size:      14px;

@heading-font-weight:       400; // жирность шрифта заголовков



//== ССЫЛКИ
//
//## Основа веба, все дела
//##
@link-color:               #670c34;
@link-hover:               #b1356c;
@link-active:              #b1356c;
@link-visited:             #363d82;

@link-font-size:           18px;
@link-font-weight:         300;
@link-text-decoration:     underline; // "underline", "none"



//== СПИСКИ
//
//## и так, пункт первый:
//#

@list-margin-bottom:                   @paragraph-margin-bottom;

//# unordered list
@unordered-list-margin-left:           25px;
@unordered-list-padding-left:          28px; // место под маркер списка
//# unordered item
@unordered-list-line-height:           24px;
@unordered-list-item-marker-top:       (@unordered-list-line-height / 2) - (@unordered-list-item-marker-height / 2);
@unordered-list-item-marker-width:     7px;
@unordered-list-item-marker-height:    7px;
@unordered-list-item-border-radius:    7px;

@unordered-list-marker-color:          @primary-color-2;


//# ordered list
@ordered-list-padding-left:            4px;
//# ordered item
@ordered-list-item-padding-left:       28px; // место под маркер списка
@ordered-list-item-marker-color:       @primary-color;
@ordered-item-marker-font-size:        16px;
@ordered-item-line-height:             24px;
@ordered-item-markger-font-weight:     500;



//== таблицы
//
//## табличные данные
//##
@table-border-spacing:                 2px 2px; // 2 значения по оси x, y
@table-margin-bottom:                  50px;
@table-striped-background-color:       #b1356c; // цвета фона полос таблицы
@table-tr-hover-background-color:      #f5f5f5;

//# размеры ячеек
@table-cell-padding-top:               6px;
@table-cell-padding-bottom:            6px;
@table-cell-padding-left:              20px;
@table-cell-padding-right:             20px;

//# параметры текста
@table-th-vertical-align:              middle;
@table-th-text-align:                  left;
@table-th-font-weight:                 500;
@table-th-color:                       #fff;

@table-td-vertical-align:              middle;
@table-td-text-align:                  left;
@table-td-font-weight:                 400;



//== цитаты
//##
@blockquote-font-size:                 @font-size;
@blockquote-border-color:              @gray;

//== элемент важно и комментарий
//##
@important-background-color:           #b1356c;
@comment-background-color:             #244373;



/**
* 5. КОМПОНЕНТЫ (кнопки, хлебные крошки, пагинация, js-компоненты (слайдер, спойлер))
* -----------------------------------------------------------------------------
*/

//== СПОЙЛЕР
//
//## простой виджет (скрывает / показывает текст). Не путать с аккордеоном
//#
@spoiler-margin-top:                         0;
@spoiler-margin-bottom:                      20px;

@spoiler-title-normal-color:                 @link-color;
@spoiler-title-active-color:                 @link-hover;
@spoiler-title-hover-color:                  @link-hover;

@spoiler-title-font-weight:                  400;
@spoiler-title-font-size:                    18px;
@spoiler-title-border-width:                 1px;
@spoiler-title-border-type:                  dashed;
@spoiler-title-border-color:                 @link-color;



//== BREADCRUMB (ХЛЕБНЫЕ КРОШКИ)
//
//## Основные параметры хлебных крошек, которые меняются от сайта к сайту
//## Для детальных изменений меняйте шаблон wm.breadcrumb

@breadcrumb-color-normal:       #1976cc;
@breadcrumb-color-hover:        #1976cc;
@breadcrumb-text-transform:     none;
@breadcrumb-font-weight:        300;
@breadcrumb-font-size:          16px;
@breadcrumb-line-height:        18px;
@breadcrumb-padding-top:        4px;
@breadcrumb-padding-bottom:     5px;
@breadcrumb-icon-display:       inline-block; // "none" или "inline-block"
@breadcrumb-separator:          '>';



//== BUTTONS (КНОПКИ)
//
//## Основные параметры кнопок на сайте
//##
@button-padding-top:                15px;
@button-padding-bottom:             15px;
@button-padding-left:               24px;
@button-padding-right:              24px;

@button-border-width:               2px;
@button-border-style:               solid;
@button-border-radius:              4px;

@button-font-size:                  16px;
@button-font-weight:                400;

@button-transition-duration:        0.3s;


//## small button
@button-sm-padding:                 4px 7px;
@button-sm-border-radius:           3px;
@button-sm-font-size:               14px;


//## button primary
@button-primary-color:              #fff;
@button-primary-bg:                 @header-color-1;
@button-primary-border:             @header-color-1;

@button-primary-color-hover:        #fff;
@button-primary-bg-hover:           #207ace;
@button-primary-border-hover:       #207ace;


//## button secondary
@button-secondary-color:              @header-color-1;
@button-secondary-bg:                 #fff;
@button-secondary-border:             #fff;

@button-secondary-color-hover:        #fff;
@button-secondary-bg-hover:           #207ace;
@button-secondary-border-hover:       #fff;


//## button transparent
@button-transparent-color:          #fff;
@button-transparent-border:         #fff;

@button-transparent-color-hover:    @primary-color;
@button-transparent-bg-hover:       #fff;
@button-transparent-border-hover:   #fff;


//## button disabled
@button-disabled-bg-color:          #bcbcbc;
@button-disabled-border-color:      #bcbcbc;
@button-disabled-color:             #7f7f7f;


//## button link
@button-link-text-decoration:    @link-text-decoration;

@button-link-color:              @link-color;
@button-link-bg:                 transparent;
@button-link-border:             transparent;

@button-link-color-hover:        @link-hover;
@button-link-bg-hover:           transparent;
@button-link-border-hover:       transparent;


//== Пагинация
//
//##
//#
@pagination-main-color:         #b1356c;
@pagination-align:              left; // left, center, right
@pagination-margin-bottom:      @paragraph-margin-bottom * 2;
@pagination-font-size:          14px;
@pagination-font-weight:        400;
@pagination-text-color:         #670c34;
@pagination-control-size:       40px; // размер одного пункта (квадрат)

//== Табы
//
//## много настроек
//#
@tab-text-color:                #656565;
@tab-text-background-color:     #eff0f1;
@tab-text-font-size:            16px;
@tab-text-transform:            uppercase;
@tab-control-max-width:         350px;


//== Аккордеон (на основе bootstrap collapse)
//
//## настроек нет
//#

//== формы
//
//## форма с перезагрузкой страницы
//#
// label
@form-label-text-color:         #424554;
@form-label-font-size:          14px;
@form-label-font-weight:        300;

// common colors (общие цвета контролов формы)
@form-focus-control-color:      @primary-color-2; // не должен быть красным
@form-error-border-color:       #b86969;
@form-error-background-color:   #f6dede;

// elements options
@form-input-height:             30px;
@form-elements-border-radius:   1px;
@form-textarea-min-height:      110px;
@form-textarea-resize:          vertical; //  none | both | horizontal | vertical | inherit



//== стилизация плагина select2
//
//##
//# сам блок
@select2-block-height:                      32px;
@select2-block-background-color:            #ececec;
@select2-block-border-color:                1px solid #aaa;
@select2-block-border-radius:               1px;
@select2-block-text-color:                  #646464;

//# список
@select2-optins-item-padding:               6px; // влияет на размер одного пункта option
@select2-options-background-color:          @primary-color-2; // при наведении на пункт
@select2-options-text-color:                #fff;

@select2-options-selected-background-color: #ddd; // выбранный пункт
@select2-options-selected-text-color:       #646464;
