/*10 08 2017 xs*/
/*header*/
.header {
	background: @header-color-1;
	//background: url(../img/tmp/img01.jpg) 50% 0 no-repeat;
}
.header-logo-wrapper {
    height: 96px;
    min-width: 352px;
    overflow: hidden;
    position: relative;
    top: 6px;
    width: 246px;
}
.header-logo__image {
    display: block;
    height: auto;
    margin-bottom: 15px;
    max-height: 100%;
    max-width: 345px;
    padding-left: 16px;
    padding-top: 7px;
    width: auto;
}
.header-nav-list {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
}
.header-nav-item {
	flex-grow: 1;
	border-right: 1px solid #a4c4e8;
	border-top: 1px solid #a4c4e8;
	&:last-child {
		border-right: 0; 
	}
}
.header-nav-link {
	display: block;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 18px;
	padding-top: 8px;
	padding-bottom: 8px;
	font-weight: 500;
	transition: background 0.5s;
	&:hover {
		background: #207ace;
	}
}

.top-menu-wrap {
	background: @header-color-2;
}
.top-menu {
	display: flex;
	flex-wrap: nowrap;
	align-content: flex-start;

}
.top-menu-item {
	margin-right: 1px;
}
.top-menu-link {
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	display: block;
	padding: 8px 14px;
	text-decoration: none;
	position: relative;
	opacity: 0.7;
	transition: opacity 0.3s;
	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 1px;
		height: 14px;
		background: #fff;
		top: 13px;
		right: 0;
	}	
	&:hover {
		opacity: 1;
	}
}
.top-menu .top-menu-item:last-child .top-menu-link::before {
		display: none;
	} 

.icons-wrap {
	display: flex;
	align-content: flex-end;
	max-height: 40px;
	padding-left: 27px;
	.icon-link {
		flex-grow: 1;
		display: block;
		border-right: 2px solid #1f4c82;
		background: #0e3360;
		transition: background 0.3s;
		display: flex;
		align-items: center;
		text-align: center;
		&:hover {
			background: @header-color-1;
		}
		&:last-child {
			border: 0;
		}
	}
	.icon {
		max-width: 25px;
		max-height: 25px;
		margin: auto;
	}
}

.footer-bottom-wrap .icons-wrap {
	max-height: inherit;
}
.footer-bottom-wrap .icons-wrap .icon-link {
	width: 40px;
	height: 40px;
	padding: 0px;
	background: #fff !important;
	margin-right: 2px;
	margin-bottom: 2px;
}
.footer-bottom-wrap .icons-wrap .icon-link .icon {
	width: 24px;
	height: 24px;
}

.font-size-wrap {
	.icon-link {	
		display: block;	
		transition: background 0.3s;
		width: 40px;
		height: 40px;
		&:hover {
			background: @header-color-1;
		}
	}
	&-footer {
		.icon-link {
			position: relative;
			top: 5px;
			//left: 10px;
		}
	}
}


.lang-wrap {
	display: flex;
	.lang-link {
		font-size: 16px;
		color: #fff;
		display: inline-block;
		vertical-align: top;
		text-transform: uppercase;
		line-height: 39px;
		width: 40px;
		font-weight: 400;
		text-decoration: none;
		&:first-child {
			position: relative;
			padding-right: 5px;
			&:before {
				content: "";
				position: absolute;
				display: block;
				width: 2px;
				height: 18px;
				background: #fff;
				top: 10px;
				right: 7px;
			}
		}
		&:hover {
			font-weight: 600;
		}
	}
}

.test {
	background: url(../img/tmp/img02.jpg) -25px 0 no-repeat;
	height: 300px;
} 

.buy-a-ticket-wrap {
	display: flex;
	align-items: center;
	.btn {
		font-size: 18px;
	}
}
.quality-hand-wrap {
	display: flex;
	align-items: center;


	.icon-link {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;
		text-decoration: none;
		font-weight: 300;
		line-height: 18px;		
		.quality-hand {
			width: 70px;
			height: 51px;
			border: 2px solid #fff;
			fill: #fff;
			border-radius: 4px;
			margin-right: 20px;
			background: @header-color-1;
			transition: background 0.3s;
			padding: 5px;
		}
		&:hover {
			//text-decoration: underline;
			.quality-hand  {
				background: @header-color-2;
			}
		}
	}

}



.main-content .container-wide .content.white-fon {
	background: #fff;
	border: 1px solid #e2e2e2;
	padding: 15px 26px 15px 26px;
}



/*footer*/

.footer .container-wide .footer-fon {
	background: #f1eee9;
	position: relative;
	//background: url(../img/tmp/img03.jpg) 50% 0 no-repeat;
}
.footer {
	hr {
		margin: 19px 10px 0;
		background: #c7c7c7;
		height: 1px;
		border: 0;		
	}
}

/*footer menu*/
.footer-menu {
	display: flex;
	flex-wrap: nowrap;
}
.footer-menu-item {
	flex-grow: 1;
	display: block;
	padding: 22px 10px 0 10px;
	flex-basis: auto;
}
.footer-menu-link {
	color: #000;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	margin-bottom: 10px;
	display: inline-block;
}
/*footer sub menu*/
.footer-sub-menu-item {
	margin-bottom: 4px;
}
.footer-sub-menu-link {
	color: #6e6b6b;
	font-size: 16px;
	font-weight: 300;
}


.footer-bottom-wrap .btn--primary {
	white-space: nowrap;
	margin-bottom: 12px;
	width: 100%;
}
.footer-bottom-wrap  {
	padding: 15px 10px;
	.quality-hand-wrap  {
		min-width: 190px;
	}
	.quality-hand-wrap .icon-link .quality-hand {
		width: 76px;
		margin-right: 5px;
		background: transparent;
		fill: #c1bdb7;
		border-color: #c1bdb7;
	}
	.quality-hand-wrap .icon-link {
		color: #000;
		font-size: 12px;
	}
}

.totop-wrap {
	position: absolute;
	right: 40px;
}
.totop {
	background: #b1356c;
	width: 40px;
	height: 40px;
	display: block;
	padding: 5px;
	position: fixed;
	bottom: 50px;
	cursor: pointer;
	z-index: 1;
	.icon {
		transform: rotate(-90deg);
		width: 30px;
		height: 30px;
	}
}

.contacts-block-wrap {
	display: flex;
	justify-content: center;
	justify-content: space-around;

}
.contacts-block {
	.title {
		font-size: 16px;
		font-weight: 300;
		margin: 0;
		text-transform: none;
	}
	.phone {
		font-size: 16px;
		font-weight: 400;
		color: #34081e;
		text-transform: uppercase;
		margin: 0 0 10px 0;
		a {
			color: #34081e;
			text-decoration: none;
			font-size: 24px;
			font-weight: 400;
		}
		&_divider {
			margin-left: 12px;
			margin-right: 12px;
		}
	}
	.address {
		font-size: 20px;
		font-weight: 400;
		color: #34081e;
		text-transform: uppercase;
		margin: 0;
	} 
	
}
.footer-bottom-wrap {
	.font-size-wrap {
		.font {
			fill: #6e6b6b;
			width: 40px;
			height: 40px;
			transition: fill 0.3s;
			&:hover {
				fill: #fff;
			}
		}
	}
}

.footer-bottom-wrap  {
	.icons-wrap {
    	align-content: flex-start;
    	flex-wrap: wrap;
    	padding-left: 0;
    	padding-top: 4px;
    	margin-bottom: 26px;
	}
	.icons-wrap .icon-link {
		background: transparent;
		border: 0;
		.icon {
			max-width: 45px;
			max-height: 45px;
			fill: #6e6b6b;
			transition: fill 0.5s;
			background: #fff;
		}
		&:hover {
			.icon {
				fill: #1f4c82;
			}
		}
	}
}
.copyright {
	font-size: 13px;
	color: #34081e;
	font-weight: 300;
	line-height: 18px;
	margin-bottom: 25px;
}

.btn--primary.whith-ico {
	display: flex;
	align-items: center;
	padding: 4px;
	padding-left: 8px;
	.icon {
		fill: #3e90dc;
		width: 29px;
		height: 37px;
	}
	span {
		padding-left: 10px;
	}
}


.news-and-photo-wrap {
	margin-bottom: 30px;
}

@news-height: 230px; //жестко задаем высоту блока одной новости

.content-block {
	background: #fff;
	//background: url(../img/tmp/img08.jpg) 0 0 no-repeat;
	.content-block-title {		
		border-bottom: 3px solid @primary-color-2;
		min-height: 62px;
		padding: 11px 10px;
		display: flex;
		justify-content: space-between;
		.h1 {
			margin-bottom: 0;
		}
		.content-block-title-link {
			color: @text-color;
			padding: 6px 23px  0 0;
			font-weight: 300;
		}
	}
	.content-block-text {
		padding-right: 10px;
	}
}

.main-news-wrap {
	padding-right: 0px;
}	
.main-news__image-wrap { 
	display: flex;
	flex-direction: row;
	min-height: @news-height;
	background-size: cover;
	background-repeat: no-repeat;
}
.main-news__tag-wrap {
	padding: 6px 12px 12px 10px;
	max-height: 40px;
	background: @primary-color-2;
	.h3 {
		color: #fff;
		margin-bottom: 0;
		font-size: 18px;
	}
}
.main-news__text-wrap {
	padding: 18px 10px 20px 20px;
	line-height: 1.4;
	height: @news-height; //жестко задаем высоту блока одной новости
	border: 1px solid #e2e2e2;
	p {
		line-height: 1.4;
	}
	.news-title {
		font-size: 18px;
		font-family: @title-font;
		margin-bottom: 7px;
		display: block;
	}
}

.news-wrap {
	background: #f4f4f4;
	padding: 0;
}
.news-one {
	display: flex;
	border-bottom: 2px solid #e2e2e2;
	height: @news-height; //жестко задаем высоту блока одной новости
	.news-one__left-col {
		display: flex;
		flex-direction: column;
		padding-top: 30px;
		padding-left: 20px;
		padding-right: 19px;
	}
	time {
		display: block;
		background: #fff;
		text-align: center;
		width: 80px;
		height: 80px;
		.day {
			font-size: 48px;
			color: @dark-font;
			font-weight: 500;
			//margin-bottom: -10px;
			display: block;
		}
		.month {
			position: relative;
			top: -20px;
			color: @dark-font;
			font-size: 14px;
			display: block;
		}
	}
	.news-one__right-col {
		padding-top: 26px;
		padding-right: 20px;
		line-height: 1.4;
		p {
			line-height: 1.4;
		}
	}
	.news-title {
		font-size: 18px;
		font-family: @title-font;
		margin-bottom: 7px;
		display: block;
		line-height: 1.3;
	}
}

@media (max-width: 1024px) {
	@news-height: 300px; //жестко задаем высоту блока одной новости
	.main-news__image-wrap { 
		min-height: @news-height;
	}
	
	.main-news__text-wrap {		
		height: @news-height; 		
	}
	.news-one {
		height: @news-height;
	}

}


.block-photo {
	.content-block {
		display: flex;
		flex-direction: column;
	}
	.content-block-text {
		height: 100%;
		padding: 0;
		
	}
	.photo {
		flex-grow: 1;
	}
	img {
		//width: 100%;
		//height: 100%;
		min-height: 100%;
		width: 100%;
		margin-bottom: 0;
	}
}
.photo {
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.partners-block {
	margin-top: 30px;
}

.partners-wrap {
	display: flex;
	.partner {
		padding: 30px;
		border-right: 1px solid @gray;
		display: flex;
		align-items: center;
		&:last-child {
			border-right: 0;
		}
	}

}

.promo {
	//background: url(../img/tmp/img07.jpg) 0 0 no-repeat;
}

.promo {
	min-height: 527px;
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
}
.main-show-wrap {	
	padding-left: 10px;
	padding-right: 0;
	display: flex;
}
.main-show-wrap__main-show-img-wrap {
	display: flex;
	align-items: flex-end;
	background-size: cover;
	background-repeat: no-repeat;	
	width: 100%;
}
.main-show-wrap__main-show-info {
	background-color: rgba(11, 10, 16, 0.7);
	width: 100%;
	color: #fff;
	padding: 10px;

	.h1 {
		color: #fff;
		font-size: 36px;
		margin-bottom: 10px;
	}
	p {
		color: #fff;
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 10px;
	}
	a {
		color: #fff;
		font-size: 16px;
	}
	.age {
		margin-left: 20px;
	}
}
.age {
	display: inline-block;
	background-color: #fff;
	font-size: 16px;
	color: #000;
	padding: 4px 12px;
	font-family: @main-font;
	font-weight: 400;
	position: relative;
	top: -5px;
}


.calendar-container {
	padding: 0;
	padding-right: 10px;
	display: flex;	 
}
.calendar-wrap {
	background-color: @site-color-4;
	width: 100%;
}

.show-wrap {
	display: flex;
	padding: 6px;
	max-height: 111px;
	border-bottom: 2px solid @gray;
	margin-bottom: 10px;

	&:last-child {
		border-width: 0;
	}
}

.show-name {
	width: 80%;
	a {
		font-size: 18px;
		font-family: @title-font;
		display: block;
		margin-bottom: 0px;
	}
	.time {
		background: transparent none repeat scroll 0 0;
	    color: @dark-font;
	    display: inline-block;
	    font-size: 24px;
	    font-weight: 600;
	    height: auto;
	    margin-bottom: -8px;
	    width: auto;
	}
	.place {
		color: @dark-font;
	}
}
.show-time {
	width: 20%;
	.age {
		top: -2px;
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: 22px;
	}
	.btn {
		font-size: 12px;
		font-weight: 400;
		padding: 8px 0;
		white-space: normal;
	}
	.btn.btn--disabled {
		background-color: @site-color-4;
		border-color:  @site-color-4;
		padding: 0;
	}
}

.submenu-wrap {
	background-color: @primary-color-2;
	display: flex;
    justify-content: space-between;
	@media screen and (max-width:767px) {
		display: none;
	}
}
.submenu-list {	
	display: inline-flex;
}

.submenu-link  {
	color: #fff;
	font-size: 14px;
	text-decoration: none;
	padding: 10px 20px;
	display: block;
	font-weight: 400;
	transition: color 0.2s;
	font-family: @main-font;
	white-space: nowrap;
	font-family: "Arial Narrow", Arial;
}

.submenu-item
{
	background-color: @primary-color-2;
	transition: background-color 0.4s;
	border-right: 1px solid #fff;
	font-family: "Arial Narrow", Arial;
	&.select {
		background-color: #fff;
		.submenu-link {
			color:  @primary-color-2;
		}
	}
	&:hover {
		background-color: #fff;
		.submenu-link {
			color:  @primary-color-2;
		}
	}
}

.submenu-list-for-kids {
	align-content: stretch;
	flex-wrap: wrap;

}

.download-poster {
	display: block;
	background-color: #fff;
	color: @primary-color-2;
	text-decoration: none;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 300;
	font-family: @main-font;
	height: 36px;
	line-height: 36px;
	margin-bottom: 7px;
    margin-right: 10px;
    margin-top: 7px;
	padding-left: 10px;
	padding-right: 10px;
	&:hover {
		text-decoration: underline;
	}
}

.mobile-inline-menu-list-wrap {
display: none; 
}
.desktop-inline-menu-list-wrap {
display: block;  
}

.inline-menu-wrap {
	//margin-bottom: 4px;
	border-top: 4px solid @primary-color-2;
	padding-top: 4px;
	margin-left: -8px;
	margin-right: -8px;
	padding-left: 8px;
	padding-right: 8px;
}
ul.inline-menu-list {
	display: flex;
	padding: 0;
	margin: 0;
	margin-bottom: 4px;

	li.inline-menu-item {
		padding-left: 0;
		&::before {
			display: none;
		}
	}
}

a.inline-menu-link {
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: @link-color;
    padding-bottom: 6px;
    padding-top: 6px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    text-decoration: none;    
    background: #fff;
    transition: background 0.5s ease 0s, color 0.3s;
    margin-right: 5px;
    &:hover {
    	background-color: @link-hover;
    	color: #fff;
    }
}

li.inline-menu-item.select a.inline-menu-link {
	background-color: @link-hover;
	color: #fff;
}


.phone-numbers-wrap {
	float: right;
	font-size: 18px;
	font-weight: 500;
	position: relative;
	top: -8px;
	a {
		color: #2a0404;
		text-decoration: none;
		font-size: 18px;
		font-weight: 500;		
	}
}


.affiche-date-wrap {
	background: @site-color-4;
	padding: 10px;
	border: 1px solid #e2e2e2;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 20px;
}
.show-one-wrap {	
	border-bottom: 1px solid @primary-color-2;
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	&:last-child {
		border-bottom: 0px;
	}
}
.show-one__time-img {
	display: flex;
}

.show-one__time {
	display: block;
	background: #fff;
	text-align: center;
	width: 104px;
	height: 104px;
	display: flex;
	flex-direction: column;
	.day {
		font-size: 48px;
		color: @dark-font;
		font-weight: 500;
		margin-bottom: -2px;
	}
	.month {
		position: relative;
		top: -15px;
		color: @dark-font;
		font-size: 14px;
		margin-bottom: -14px;
	}
	.weekday {
		font-size: 14px;
		font-weight: 500;
		color: @dark-font;
	}
}

.show-one-first-col {
	display: flex;
	padding-left: 11px;
}

.show-one-wrap {
	.show-one__time {
		margin-right: 15px;
	}
	.show-one__img {
		width: 104px;
		height: 104px;
		margin-right: 20px;
	}
}

.show-one__name {
	font-size: 18px;
	display: block;
	//margin-bottom: 15px;
	margin-right: 15px;
	display: inline-block;
	font-family: @title-font;
}
.show-one__about {
	max-width: 460px;
	.age {
		display: inline-block;
		font-size: 16px;
		padding-top: 2px;
		padding-bottom: 2px;
		//margin-left: 15px;
		position: relative;
		top: 0;
		margin-bottom: 15px;
	}
	p {
		font-weight: 400;
		margin-bottom: 5px;
		span {
			color: @dark-font;
			font-weight: 500;
		}
	}
}
.start-time {
	font-size: 24px;
	font-weight: 600;
	color: @dark-font;
}
.holl {
	color: @dark-font;
	white-space: nowrap;
	&:first-letter {
		text-transform: uppercase;
	}
}

.buy-ticket {
	display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    a {
    	color: #3e90dc;
    	text-align: right;
    	&:hover {
    		color: #3e90dc;
    	}
    }
}


.affiche-show-wrap {
	background: @site-color-4;
	padding: 10px;
	border: 1px solid #e2e2e2;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.show-one-second-col {
	align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.affiche-show-wrap {	
	.time-wrap {
		border-bottom: 1px solid @primary-color-2;
		width: 100%;
		padding-bottom: 10px;
		margin-bottom: 10px;
		&:last-child {
			border: 0px;
		}
		.date {
			color: @dark-font;
			font-weight: 500;
			span {
				font-weight: 300;
			}
		}
		.time {
			color: @dark-font;
			font-weight: 500;
		}
	}
}


.inner-news-wrap {
	padding: 6px 0px;
	.inner-news-one-wrap {
		margin-bottom: 20px;
	} 
	.news-one {
		background: @site-color-4;
		border: 1px solid @gray;
		height: 100%;
		min-height: 230px;
	}
}

.pagination-wrapper {
	padding-top: 20px;
} 


.main-partners-block {
	border-top: 3px solid @primary-color-2;
	margin-left: -8px;
	margin-right: -8px;
}

.partners-wrap {
	//margin-bottom: -15px;
}
.main-partners-block .partner {
	display: flex;
	flex-direction: column;
}
.partner-img {
	width: 100%;
}
.partner-text {
	.title {
		color: @dark-font;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 12px;
	}
}

.person-wrap {
	border-top: 1px solid @gray;
	margin-left: -8px;
	margin-right: -8px;
	margin-bottom: -16px;
}
.person-one {
	border-right: 1px solid @gray;
	border-bottom: 1px solid @gray;
	padding: 10px;
	text-decoration: none !important;
	&:hover {
		background: @site-color-4;
	}
}
.person-img {
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
}
.person-text .title {
	text-decoration: underline;
	font-size: 18px;
}
.person-text p {
	color: #6e6b6b;
	text-decoration: none !important;
	font-style: italic;
}


.repertuar-show-wrap {
	border-top: 4px solid #b1356c;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 20px;

}

.repertuar-show-one {
	display: flex;
	justify-content: space-between;
	background: @site-color-4;
    border: 1px solid @gray;
    margin-bottom: 7px;
    padding: 5px 10px;
}


.repertuar-show-one {
	
	.age {
		width: 80px;
		height: 80px;
		font-size: 30px;
		font-weight: 600;
		line-height: 73px;
		position: relative;
		top: -5px;
		right: -10px;
	}
	.repertuar-show-one__img {
		width: 260px;
		background: #e7e7e7;
		display: flex;
		justify-content: center;
		margin-right: 20px;
		img {
		    height: 100%;
		    width: auto;
		}
	}
	
}
.repertuar-show-one__about {
	margin-right: auto;
	.show-one__name {
		margin-bottom: 15px;
		display: block;
		font-family: @title-font;
	}
	p {
		margin-bottom: 10px;
		font-weight: 400;
		span {
			color: @dark-font;
			font-weight: 500;
		}
	}
	.about {
		text-transform: uppercase;
		font-size: 12px;
		font-family: @title-font;
	}
}

.news-one-wrap {
	margin-left: -8px;
	margin-right: -8px;
	& a {
		color: #6e6b6b;
	}
}
.inner-news-right-col {
	padding-right: 10px;
}


.big-news-wrap {
	.date {
		background: @primary-color-2;
	    display: block;
	    height: 80px;
	    text-align: center;
	    width: 80px;
	    float: left;
	    margin-right: 15px;
	
		.day {
		    color: #fff;
		    font-size: 48px;
		    font-weight: 500;
		    margin-bottom: -19px;
		}
		.month {
			color: #fff;
		}
	}
	.img-wrap {
		margin-left: 96px;
		img {
			max-width: 100%;
		}
	}
}

.inner-news-right-col {
	.news-one {
		height: auto;
		margin-bottom: 20px;
	}
}

.contacts-wrap {
	border-top: 4px solid #b1356c;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 20px;
}

.form-contacts-page {
	//background: url(../img/tmp/img01.jpg) 0 0 no-repeat transparent; //убрать
	background-color: #f4f4f4;
	width: 100%;
	min-width: auto;
	margin-bottom: 0;
	.site-form {
		padding-left: 6%;
		padding-right: 6%;
		padding-top: 16px;
	}
}

.contacts-wrap {
	.site-form__head {
		text-align: left;
		font-size: 24px !important;
		color: #2a0404 !important;
		margin-bottom: 19px;
	}
	.site-form__input {
		height: 40px;
	}
	.site-form-element-wrapper {
		margin-bottom: 24px;
		.site-form__input {
			border-color: #e2e2e2;
		}
	}
	.site-form-element-wrapper:hover,
	.site-form-element-wrapper:focus {
		.site-form__label {
			color: #207ace;
		}
		.site-form__input {
			border-color: #207ace;
		}
	}
	.site-form__input.error {
		background-color: #fff;
		border-color: #fd7676;
	}
	.site-form label.error {
		//position: absolute;
		color: #d32d2d;
	}
	.site-form__textarea {
		height: 74px;
		min-height: inherit;
		border-color: #e2e2e2;
		font-size: 15px;
	}
	.site-form__checkbox-label {
		font-size: 16px;
		display: block;
	}
	.checkbox:not(checked) + label::before {
		border-color: #e2e2e2;
	}
	.checkbox + label::after {
		border-color: #3e90dc;
		border-style: solid;
		border-width: 0 2px 2px 0;
		background: transparent;
		transform: rotate(45deg);
		width: 8px;
		height: 12px;
		left: 6px;
		top: 3px;
		opacity: 0;
	}
	.checkbox:checked + label::after {
		border-color: #3e90dc;
		border-style: solid;
		border-width: 0 2px 2px 0;
		background: transparent;
		transform: rotate(45deg);
		width: 8px;
		height: 12px;
		left: 6px;
		top: 3px;
		opacity: 1;
	}
}


.builder-description-preview {
	background-position: 50% 50%;
	background-repeat: repeat;
}

.popover-wrapper {
	background: #fff;
	border: 1px solid @gray;
	padding: 10px;
	p {
		font-size: 14px;
		margin-bottom: 5px;
	}
} 
.popover-close {
	text-align: right;
	width: 96%;
	display: block;
	padding: 3px;
}
.popover-close .icon {
	fill: #000;
	width: 14px;
	height: 14px;
}

.contacts-info {
	margin-bottom: 20px;
	p {
		margin-bottom: 10px;
		b {
			color: @dark-font;
			font-weight: 500;
		}
	}
}

.person-card__info {
	border-left: 1px solid @gray;
	hr {
		margin-left: -10px;
		margin-right: -8px;
	}
}

.person-card__photo {
	img {
		margin: 0 0 20px;
		display: block; 
	}
	padding: 0 20px 0 40px;
	.profession {
		font-weight: 500;
		color: @dark-font;
	} 
	.birthday {
		b {
			font-weight: 500;
			color: @dark-font;
		}
	}
}

.photoalbum-wrap.row {
	.photoalbum-cover {
		position: relative;
		padding: 10px;
		img {
			display: block;
		}
		.photoalbum-cover-title {
			position: absolute;
			top: 10px;
			left: 10px;
			right: 10px;
			bottom: 10px;
			background-color: rgba(11, 10, 16, 0.7);
			color: #fff;
			padding: 10px;
			transition: background-color 0.5s;
			text-decoration: underline;
			&:hover {
				background-color: rgba(11, 10, 16, 0.9);
				text-decoration: none;
			} 
		}
		
	}
}


.afisha-show-card-wrap {
	border-top: 4px solid #b1356c;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 20px;
}
.afisha-show-card-wrap-col-right {
	border-left: 1px solid @gray;
	padding-left: 20px;
	hr {
		margin: 40px -18px 40px -20px;
	}
}
.show-card-all-description-wrap {
	margin-bottom: 20px;
}
.description-wrap {
	margin-bottom: 10px;
}
.description-col-01 {
	padding-right: 0;
} 
.description-col-02 {
	font-family: @title-font;
	padding-right: 0;
}
.description-person-status {
	font-size: 14px;
	font-style: italic;
}

.press {
	a {
		display: block;
	}
	p {
		font-size: 14px;
	}
}

.review {
	font-size: 14px;
	p {
		font-size: 14px;
	}
	.review-title {
		color: @dark-font;
		font-weight: 500;
		margin-bottom: 10px;
	}
}


.in-the-poster-wrap {
	color: @dark-font;
	font-weight: 300;
	b {
		font-weight: 500;
	}
	.row {
		margin-bottom: 10px;
	}
	.in-the-poster-col-03 {
		text-align: right;
	}
}

.afisha-show-card-about-start {
	background: @site-color-4;
	border: 1px solid @gray;
	padding: 15px;
	margin-bottom: 20px;
	p {
		margin-bottom: 5px;
		font-size: 300;
		b {
			color: @dark-font;
			font-size: 500;
		}
	}
	.age {
		top: 0;
		font-size: 16.2px;
	}
}





/*slider-count*/

.slider-count {
	margin-bottom: 20px;
}
.nav-slider-count {
	margin-bottom: 20px;
	button {
		border: 0px;
	}

	.slick-list {
		margin-left: -10px;
		margin-right: -5px;
	}
}

.nav-slider-count-item {
	position: relative;
  width: 138px;
  height: auto;

	padding-left: 10px;
	padding-right: 10px;

	overflow: hidden;


}

.nav-slider-count-item-image-inner-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

}

.nav-slider-count-item-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	font-family: 'object-fit: cover;' // ie hack, страница использует полифил
}

.nav-slider-count-item-background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;

	cursor: pointer;

	background-color: transparent;
	transition: 0.15s background-color ease;

	&:hover {
		background-color: rgba(0,47,73,0.6);
	}
}

.nav-slider-count .slick-current {
	& .nav-slider-count-item-background {
		background-color: rgba(0,47,73,0.6);
		cursor: default;
	}
}

.slider-count__image {
	display: block;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 650px;

	margin-left: auto;
	margin-right: auto;
}

.slider-count__btn-prev {
	padding: 0;
	border: 0;
	background-color: transparent;

	&:focus {
		outline: 0;
	}

	& .icon {
		width: 20px;
		height: 23px;
		fill:  @link-color;

		transition: 0.15s fill ease;

		&:hover {
			fill:  @link-hover;
		}

		transform: rotateZ(180deg);
	}
}
.slider-count__btn-next {
	padding: 0;
	border: 0;
	background-color: transparent;

	&:focus {
		outline: 0;
	}

	& .icon {
		width: 20px;
		height: 23px;
		fill: @link-color;

		&:hover {
			fill: @link-hover;
		}
	}
}

.slider-count-nav-wrapper {
	text-align: center;
}

.slider-count-nav-inner-wrapper {
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	display: none;
}

.slider-count-length {
	padding-left: 15px;
	padding-right: 15px;
	color: @text-color;
}

.slider-count-wrapper {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.slider-count {
  max-width: 100%;
	margin-bottom: 15px;
}




/*header-mobile-menu*/
.header-mobile-menu-button-wrapper {
    display: block;
    height: 64px;
}
.burger-wrap {
	display: flex;
	align-items: center;
} 
.header__mobile-menu-button {
    background-color: @button-primary-bg-hover;
    border: 0 none;
    display: block;
    height: 40px;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: 40px;
    border-radius: 5px;
    border: 2px solid @button-primary-bg-hover;
    &:focus {
      outline: 0;
    }
}
.header__mobile-menu-button div {
	background: #fff none repeat scroll 0 0;
    height: 2px;
    left: 50%;
    margin-left: -12px;
    margin-top: 6px;
    position: absolute;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    width: 23px;
}
.header__mobile-menu-button div:nth-child(1) {
    top: 0;
}
.header__mobile-menu-button div:nth-child(2) {
    top: 11px;
}
.header__mobile-menu-button div:nth-child(3) {
    top: 21px;
}

.header__mobile-menu-button.active div:nth-child(1) {
    top: 10px;
    transform: rotate(45deg);
    transition: all 0.3s ease 0s;
}
.header__mobile-menu-button.active div:nth-child(2) {
    opacity: 0;
    transition: opacity 0.3s ease 0s;
}
.header__mobile-menu-button.active div:nth-child(3) {
    top: 10px;
    transform: rotate(-45deg);
    transition: all 0.3s ease 0s;
}

#header-mobile-menu {
	display: none;
}

.header-mobile-menu-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
	//display: flex;
	.header-nav-item {
		border: 1px solid #a4c4e8;
	}
}
.header-nav-list-mobile {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}
.top-menu-mobile {
	display: flex;
	flex-direction: column;
}
.mobile-menu {
	.buy-a-ticket-wrap
	{
		align-items: center;
	}
	.buy-a-ticket-wrap .btn--secondary {
		padding-left: 0;
		padding-right: 0;
		font-size: 13px;
		white-space: normal;
		padding-top: 4px;
		padding-bottom: 4px;
	}
	.header-logo__image {
		width: 100%;
		padding-left: 0;
	}
	.quality-hand-wrap  {
		justify-content: flex-end;

	}
	.quality-hand-wrap .icon-link  {
		display: inline-block;
		line-height: 10px;
	}
	.quality-hand-wrap .icon-link .quality-hand {
		width: 30px;
		height: 30px;
		margin-right: 0;

	}
	.top-menu-mobile-wrap {
		background: @header-color-2;
	}
	.top-menu-mobile {
		display: flex;
		margin-bottom: 10px;
	}
	.top-menu-link {
		padding-top: 4px;
		padding-bottom: 4px;
	}
	.top-menu-link:before {
		display: none;
	}
	.phone-wrap {
		padding: 22px 0 2px 0;
	}
	.phone-wrap a {
		color: #fff;
		font-size: 18px;
		display: inline-block;
		margin-bottom: 5px;
		margin-right: 10px;
		text-decoration: none;
	}
	.phone-wrap a:last-child {
		margin-right: 0;
	}
	.icons-wrap {
		padding-right: 0;
	}
}
.footer-bottom-wrap-desktop {
	display: flex;
}
.footer-bottom-wrap-mobile {
	display: none;
}
.footer-mobile-top-line {
	padding: 0 !important;
	.quality-hand-wrap .icon-link .quality-hand {
		width: 49px;
		height: 49px;
	}
	.btn--primary {
		height: 49px;
		padding-top: 1px;
		padding-bottom: 0;
	}
	
}
.footer-bottom-wrap-mobile {
	.contacts-block-wrap {
		justify-content: flex-start;
		padding-bottom: 10px;
	}
	.copyright {
		margin-bottom: 6px;
	}
}
.footer-mobile-top-line > div {
	padding: 5px;
}

/*//header-mobile-menu*/
.mobile-menu {
	display: none;
}
.desktop-menu {
	display: block;
} 

/*datepicker*/
.datepicker {
	width: 100%;
	border-radius: 0; 
}
.datepicker--nav-title {
	font-size: 18px;
}
.datepicker--day-name {
	color: #cccccc;
	font-size: 15px;
	font-weight: 500;
	text-transform: none;
}
.datepicker--cell {
	color: #6a6a6a;
	border-radius: 0;
	height: 38px;
}
.datepicker--cell.-current- {
	color: #fff;
	background: #b1356c;
}
.datepicker--nav {
	border-bottom: 2px solid #ebe7e7;
}

.content.white-fon.fix-integration {
    padding: 0px 8px 15px 8px !important;
    border: none !important;
    margin-top: -16px !important;
}
.age {
    text-align: center;
}

.contacts-left-col {
	flex-direction: column;
	justify-content: space-between;
	display: flex;
	@media screen and (max-width: 767px) {
		flex-direction: column-reverse;
	}
}
.map {
    height: 100%;    
}
.contacts-info-mobile {
    display: none;
} 

.partners-wrap .partner {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

a.inline-menu-link {
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
}
/*datepicker*/
.datepicker {
  width: 100% !important;
  border-radius: 0 !important; 
}
.datepicker--nav-title {
  font-size: 18px !important;
}
.datepicker--day-name {
  color: #cccccc !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}
.datepicker--cell {
  color: #6a6a6a !important;
  border-radius: 0 !important;
  height: 38px !important;
}
.datepicker--cell.-current- {
  color: inherit !important;
  background: #fff !important;
}
.datepicker--nav {
  border-bottom: 2px solid #ebe7e7 !important;
}
.datepicker--cell.-selected- {
    color: #fff !important;
  background: #b1356c !important;
}
.datepicker--cell.-disabled- {
  color: #ccc !important;
}
.datepicker--cell.-disabled-.-focus- {
  background: transparent !important;
}

.performance_annotation,
.performance_annotation p,
.performance_annotation span
{
  font-family: "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6e6b6b !important;
  text-align: left !important;
}

.submenu-link {
  padding: 10px 5px;
  font-size: 16px;
  /*letter-spacing: -0.8px;*/
  font-family: @main-font;
  align-items: center;
  display: flex;
}

.repertuar-show-one__about p {
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 1.2;
}
.repertuar-show-one__about {
    margin-right: auto;
    padding: 4px 0;
}  
.content {
    // height: 100%;
    color: #525252;
}
.repertuar-show-one__about p {
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 1.2;
}
.show-one__about p {
    font-weight: 300;
    margin-bottom: 5px;
    line-height: 1.2;
}


/*additional xs 22 09 2017 */
.submenu-link {
    height: 100%;
    display: flex;
    align-items: center;
}

.phone-numbers-wrap {
    top: -2px;
}

.phone-numbers-wrap {
    font-weight: 300;
}

.main-content .container-wide .content.white-fon {
    padding: 15px 30px;
}

.show-one__time .day {
    font-weight: 600;
}

.start-time {
    font-weight: 600;
}

.show-one__time .weekday {
    font-weight: 600;
}

.show-one__time .month {
    color: #1b191e;
    font-size: 15px;
    margin-bottom: -4px;
    position: relative;
    top: -6px;
}

.affiche-show-wrap {
    background-color: transparent;
    border: 0;
}

.show-one-wrap {
    background: #f4f4f4 none repeat scroll 0 0;
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.show-one-wrap:last-child {
    border-bottom: 1px solid #e2e2e2;
}

.affiche-show-wrap .time-wrap .time {
    font-weight: 600;
}

.affiche-show-wrap .time-wrap .date {
    font-weight: 600;
}

.show-one__time .weekday {
    font-size: 15px;
}

.affiche-date-wrap {
    border: 0px;
    background-color: transparent;
}

.download-poster {
    font-weight: 500;
}

a.inline-menu-link {
    font-size: 16px;
    font-weight: 400;
}

.show-one__img img {
    max-width: 104px;
}

.breadcrumb a {
    font-weight: 400;
}

.breadcrumb {
    padding: 4px 0 8px 28px;
}

.repertuar-show-one {
    padding: 15px 10px;
}

.repertuar-show-one .age {
    top: -15px;
}

.contacts-info .h2 {
    margin-top: 30px;
}

.contacts-info .h2:first-child {
    margin-top: 0;
}

.site-form-wrapper.form-contacts-page {
    margin-top: 20px;
}

.checkbox:not(checked) + label::before {
    top: 1px;
}

.checkbox:not(checked) + label::after {
    top: 5px;
}

#personal-error {
    position: relative !important;
}

.content .site-form__button-submit, .site-form__button-submit {
    padding-left: 62px;
    padding-right: 62px;
}

.footer-sub-menu-link:hover {
    text-decoration: none;
}

.news-one time .day {
    font-weight: 600;
}

.news-one time .month {
    top: -8px;
}

a.inline-menu-link {
    min-width: 97px;
    margin-bottom: 10px;
}

.desktop-inline-menu-list-wrap .inline-menu-list {
    margin-bottom: -2px;
}

ul.inline-menu-list {
    flex-wrap: wrap;
}

.big-news-wrap .date .day {
    font-weight: 600;
    margin-bottom: -14px;
}

.news-one__right-col .news-title {
    color: #670c34;
}

.news-one .news-one__left-col {
    padding-top: 27px;
}

.person-text p {
    font-size: 14px;
}

.person-text .title {
    font-family: "museocyrl";
    font-size: 18px;
    margin-bottom: 8px;
}

.person-one:hover .person-text .title {
    text-decoration: none;
}

.person-card__info {
    padding-right: 30px;
}

.spoiler-title-icon-wrapper .icon {
    fill: #670c34;
}

.spoiler__title {
    /*text-transform: lowercase;*/
    font-weight: 300;
}

.spoiler__title:first-letter {
    /*text-transform: uppercase;*/
}

.photoalbum-cover-title {
    /*text-transform: lowercase;*/
    font-weight: 300;
}

.photoalbum-cover-title:first-letter {
    /*text-transform: uppercase;*/
}

.top-menu {
    padding-top: 2px;
}

.top-menu-link::before {
    top: 11px;
}

.content.white-fon.fix-integration {
    padding: 0px 8px 15px 8px !important;
    border: none !important;
    margin-top: -16px !important;
}

.age {
    text-align: center;
}



.partners-wrap .partner {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

a.inline-menu-link {
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
}

/*datepicker*/
.datepicker {
    width: 100% !important;
    border-radius: 0 !important;
}

.datepicker--nav-title {
    font-size: 18px !important;
}

.datepicker--day-name {
    color: #cccccc !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
}

.datepicker--cell {
    color: #6a6a6a !important;
    border-radius: 0 !important;
    height: 38px !important;
}

.datepicker--cell.-current- {
    //color: #fff !important;
    //background: #b1356c !important;
}

.datepicker--nav {
    border-bottom: 2px solid #ebe7e7 !important;
}

.datepicker--cell.-selected- {
    color: #fff !important;
    background: #b1356c !important;
}

.datepicker--cell.-disabled- {
    color: #ccc !important;
}

.datepicker--cell.-disabled-.-focus- {
    background: transparent !important;
}

.performance_annotation,
.performance_annotation p,
.performance_annotation span {
    font-family: "helveticaneuecyr", Helvetica, Tahoma, Arial, sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #6e6b6b !important;
    text-align: left !important;
}
.repertuar-show-wrap {
    padding-left: 0;
    padding-right: 0;
}

/**
* gallery page
*/
.gallery-page-list {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;

	margin-bottom: 85px;
}

.gallery-page-list-item {	
	height: 300px;
	margin-bottom: 20px;
}

.gallery-page-list-item-inner-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	height: 100%;
	padding: 27px;

	background-position: 50% 0;
	background-repeat: no-repeat;
	background-color: rgba(0,47,73,0.6);
	background-size: cover;

	text-decoration: none !important;

	&:hover {
		& .galley-page__head {
			color: #fff;
		}

		& .btn__gallery-page {
			background-color: #207ace;
			border-color: #207ace;
		}
	}
}
.galley-page__head {
	font-size: 23px;
	color: #fff;
	border-bottom-color: #fff;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	text-decoration: none !important;

	transition: 0.15s color ease, 0.15s border-bottom-color ease;
}


.gallery-footer-wrapper {
	text-align: center;
}


.photo-wrap {
	margin-bottom: 20px;
}
.photo-one {
	display: flex;
	justify-content: center;
	align-items: center;
	align-items: stretch;
	margin-bottom: 20px;
	img {
		max-width: 100%;
	}
}
.photo-one-link {
	background: #ccc;
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.photo-wrap {
	max-width: 100%;
	max-height: 100%;
}


.hall td {
	min-width: 24px;
	text-align: center;
}
table.hall td.e {
    cursor: pointer;
}
.legenda {
	padding-top: 20px;
}
.colorplace {
	display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
    top: 7px;
}
.online-check {
	padding-bottom: 20px;
	padding-top: 11px;
}
p.mesta_green span {
    background: rgb(184, 222, 161);    
}
p.mesta_gray span {
    background: rgb(212, 212, 212);
}
p.mesta_orange span {
    background: rgb(244, 170, 109);
}
table.hall td.d {
    background-color: rgb(212, 212, 212);
    color: rgb(136, 136, 136);
}
.kolvo_biletov {
	padding-top: 11px;
}

.form-contacts-page {

	position: relative;

	& .success {

		position: absolute;
		top: 0;
		left: 0;
		background-color: #f4f4f4;

		width: 100%;
		height: 100%;

		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: center;
		align-items: center;

		z-index: 3;

		display: none;

	}

}
