@media (max-width: 1735px) {
  
} // end max-width: 1600px

@media (max-width: 1600px) {
  
} // end max-width: 1600px


@media (max-width: 1280px) {
  
}

@media (max-width: 1200px) {
  .icons-wrap {
    padding-left: 0;
  }
  .icons-wrap .icon {
    width: 35px;
  }
  .lang-wrap {
    padding: 0;
  }
  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  
  /*footer phone*/
  .contacts-block .phone_divider {
    display: none; 
  }
  .contacts-block .phone a {
    display: block;
  }

} // end max-width: 1200px



@media (max-width: 1024px) {
  .icons-wrap {
    padding-left: 0;
  }
  .icons-wrap .icon {
    width: 35px;
  }
  .lang-wrap {
    padding: 0;
  }


  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  .footer-bottom-wrap .btn--primary {
    width: auto;
  }

  .in-the-poster-wrap .in-the-poster-col-03 {
    padding-right: 5px;
    .btn--primary, .content .btn--primary {
      padding-right: 10px;
      padding-left: 10px; 
    }
  }

  .photoalbum-wrap.row .photoalbum-cover .photoalbum-cover-title {
    font-size: 13px;
  }


  

} // end max-width: 1024px



@media (max-width:1024px ) and (orientation: landscape) {
  


}


@media (max-width:820px ) {
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  } 
  .header-mobile-menu-button-wrapper {
    //height: auto;
    height: 108px;
  }
  .header-mobile-menu {
    height: auto;
    height: ~"calc(100vh - 108px)";
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background: @header-color-1;
  }
  .footer-menu {    
    flex-wrap: wrap;
  }
  .footer-menu-item {
    width: 25%;    
  }
  .header__mobile-menu-button {
    margin: 0 auto;
  }
  .header-logo-wrapper {
    display: block;
    margin: 0 auto;
  }
  .buy-a-ticket-wrap .btn {
    margin: 0 auto;
  }
  .footer-sub-menu-link {
    white-space: normal;
  }
  .footer-bottom-wrap .btn--primary {
    white-space: normal;
  }
  .footer-bottom-wrap .quality-hand-wrap {
    min-width: inherit;
  }
  .footer-bottom-wrap .font-size-wrap .font {
    width: 40px;
    height: 40px;
  }
  .footer-bottom-wrap-desktop {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: block;
  }
  
}




@media (max-width: 768px) {
  .container-wide {
    padding-right: 0;
    padding-left: 0;
  }
  .row {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .lang-wrap .lang-link {
    width: 30px;
    font-size: 13px;
  }
  .lang-wrap .lang-link:first-child:before {
    right: 6px;
  }
  .top-menu {
    display: inline-flex;
  }
  .top-menu-link {
    line-height: 15px;
  }
  .icons-wrap {
    display: inline-flex;
    align-items: center;
  }
  .icons-wrap .icon-link {
    text-align: center;
  }
  .icons-wrap .icon {
    width: 24px;
    padding-top: 9px;
  }
  .header-logo__image {
    width: 240px;
  }
  .header-nav-link {
    font-size: 15px;
  }
  .block-photo {
    min-height: 200px;
    margin-top: 30px;
  }
  .main-news-wrap {
    padding-left: 0;
  }
  .partners-block {
    margin-left: 10px;
    margin-right: 10px;
  }
  .container-wide .partners-block {
    margin-left: 0;
    margin-right: 0;
  }
  .footer-bottom-wrap .btn--primary {
    padding-left: auto;
    padding-right: auto;
    font-size: 13px;
  }
  .contacts-block .phone a {
    display: inline-block;
  }
  .contacts-block .phone span {
    display: none;
  }
  .contacts-block .address {
    font-size: 13px;
  }
  .icons-wrap .icon {
    padding-top: 0;
  }
  .map {
    height: 500px;
    margin-top: 20px;
  }
  .show-one-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .show-one-first-col {
    padding-left: 0;
  }
  .show-one__about .age {
    margin-left: 0;
    margin-bottom: 5px;
  }
  .show-one__name {
    margin-bottom: 5px;
  }
  .start-time {
    font-size: 16px;
  }
  .holl {
    white-space: normal;
  }
  .show-one-first-col {
    flex-direction: column;
  }
  .show-one__time-img {
    display: flex;
    margin-bottom: 20px;
  }
  .show-one-first-col-afisha-show {
    flex-direction: row;
  }
  .buy-ticket {
    align-items: flex-start;
    padding-left: 0;
    padding-bottom: 10px;
  }
  .show-one__about {
    padding-left: 10px;
  }
  .show-one-wrap .show-one__img {
    margin-right: 0;
  }
  .description-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .description-col-01 {
    padding-left: 0;
  }
  .in-the-poster-row {
    //flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .in-the-poster-col-01 {
    padding-left: 0;
  }
  .in-the-poster-col-02 {
    padding-left: 0;
  }
  .form-contacts-page .site-form {
    padding-right: 0;
    padding-left: 0;
  }
  .content-block .content-block-text {
    padding-right: 0;
    padding-left: 0;
  }
  .inner-news-right-col {
    padding-right: 0px;
  }
  .footer-menu-item {
    padding-right: 0;
    width: 50%;
  }
  .person-card__photo {
    padding-left: 0;
  }
  .person-page {
    align-content: stretch;
  flex-wrap: wrap;
  }
  .person-wrap {
    padding-left: 0;
    padding-right: 0;    
  }
  .person-one:nth-child(3n+1) {
      border-left: 1px solid #e2e2e2;
    }
  .show-wrap {
    max-height: inherit;
  }
  .submenu-list {
    justify-content: center;
  }
  .submenu-item {
    flex-grow: 1; 
  }
  .download-poster {
    margin-left: 5px;
  }
  .gallery-footer-wrapper .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-bottom-wrap .icons-wrap {
    margin-bottom: 12px;
  }
  .icons-wrap {
    max-height: inherit;
  }
  .icons-wrap .icon-link {
    padding: 10px;
    margin-bottom: 20px;
  }
  .main-content .container-wide .content.white-fon {
    padding: 15px 12px;
  } 
} // max-width: 768px






@media (max-width: 610px) {
  .header-logo-wrapper {
    width: auto;
    max-width: 100%;
    min-width: inherit;
    height: auto;
  }
  .header-logo__image {
    max-width: 100%;
    width: 100%;
  }
  .header-mobile-menu-button-wrapper {
    height: 75px;
  }
  .header-logo-wrapper  {
    height: 60px;
  }  
  .header-mobile-menu {
    height: ~"calc(100vh - 75px)";
  }
}


@media (max-width: 414px) {

.h1,
  h1,
  .content h1,
  .content .h1,
  .h2,
  h2,
  .content h2,
  .content .h2,
  .h3,
  h3,
  .content h3,
  .content .h3 {
    font-size: @heading-h1-font-size * 0.75;
    line-height: 1.5;
  }
  
  .footer-bottom-wrap-desktop {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: flex;
  }

  .footer-menu {
    flex-direction: column;
  }
  
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }

  .main-show-wrap {
    padding-left: 0;
  }
  .calendar-container {
    padding-right: 0;
  }
  .main-show-wrap__main-show-img-wrap {
    min-height: 350px;
  }
  .partners-wrap {
    padding: 10px;
  }
  .partners-wrap .partner {
    border: 1px solid #e2e2e2;
    margin-bottom: -1px;
    margin-left: -1px;
  }
  .partners-wrap .partner:last-child {
    border: 1px solid #e2e2e2;
  }

  /*footer*/
  .footer-bottom-wrap {
    display: none;
  }
  .footer-bottom-wrap-mobile {
    display: block;
  }
  .footer-menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-menu-item {
    //border: 1px solid #ccc;
  }
  .footer-menu-item-01 {
    order: 1;
  }
  .footer-menu-item-02 {
    order: 2;
  }
  .footer-menu-item-03 {
    order: 3;
  }
  .footer-menu-item-04 {
    order: 4;
  }
  .footer-menu-item-05 {
    order: 5;
  }
  .footer-menu-item-06 {
    order: 6;
  }
  .footer-menu-item-07 {
    order: 7;
  }
  .footer-menu-item-08 {
    order: 8;
  }


  .news-and-photo-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .promo {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .show-time .age {
    top: 6px;
  }

  .news-one {
    height: auto;
  }
  .main-news__text-wrap {
    height: auto;
  }


  .repertuar-show-one {
    flex-direction: column;
  }

  .repertuar-show-one .repertuar-show-one__img {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .repertuar-show-one .age {
        width: 57px;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: 7px;

  }
  
  .afisha-show-card-wrap-col-right {
    border-left: 0px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    padding-left: 0px;
    padding-top: 20px;
    margin-top: 20px;
  }

  .breadcrumb {
    padding-left: 8px;
  }
  .phone-numbers-wrap {
    float: none;
  }
  .phone-numbers-wrap {
    font-size: 15px;
  }

  .submenu-list {
    flex-wrap: wrap;
  }
  
  .show-one-first-col-afisha-show {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  .buy-ticket {
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  .affiche-show-wrap .time-wrap:last-child {
    margin-bottom: 0;
  }
  .buy-ticket {
    align-items: center; 
  }
  .download-poster {
    line-height: 16px;
    height: 42px;
    padding-top: 5px;
  }

  .site-form-wrapper {
    min-width: inherit;
  }
  ul.inline-menu-list {
    flex-wrap: wrap;
  }

  .person-one {
    //border-left: 1px solid #e2e2e2;
  }
  .person-wrap {
    padding-bottom: 10px;
  }

  .person-card__info {
    border-left: 0px solid #e2e2e2;
  } 
  
  .show-one__time-img {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 14px;
  }
  .affiche-date-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .affiche-date-wrap .buy-ticket {
    border-top: 0 solid #ccc;
    padding-top: 0;
    align-items: flex-end;
  }
  .affiche-date-wrap .show-one-first-col {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-right: 0;
  }
  .show-one__time-img {
    padding-left: 10px;
  }

  .mobile-inline-menu-list-wrap {
    display: block; 
    padding: 10px;
    .row {
      padding-left: 0;
    }
    .select-wrap {
      padding-left: 0;
    }
  }
  .desktop-inline-menu-list-wrap {
    display: none;  
  }
  .select-wrap select {
    height: 30px;
    font-size: 14px;
  }
  .breadcrumb a {
    font-size: 13px;
  }
  .pagination a, .content .pagination a, .pagination span, .content .pagination span {
    line-height: 35px;
  }
  .pagination li, .content .pagination li {
    height: 35px;
    width: 35px; 
  }
  .mobile-menu {
    //position: relative;
  }
  .header-mobile-menu {
    height: ~"calc(100vh - 55px)";
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background: @header-color-1;
  }
  .footer-sub-menu-link {
    font-size: 13px;
  }

  .submenu-item {
    border-top: 1px solid #fff;
    margin-bottom: -1px;
  }
  .submenu-link {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .download-poster {
    margin-left: 5px;
  }

  .top-menu-mobile-wrap {
    padding-bottom: 10px;
  }
  .icons-wrap {
    max-width: 100%;
    flex-basis: 100%;
    align-items: flex-start;
    align-content: flex-start;
  }
  .icons-wrap .icon-link {
    flex-grow: 0;
    margin-right: 5px;
    padding: 8px;
  }

  .map-wrap {
    flex-basis: 100%;
    max-width: 100%;
    padding-top: 20px;
  }
  .map {
      height: ~"calc(100vh - 200px)" !important;
      display: flex;
      flex-basis: 100%;
      max-width: 100%;  
  }
  .contacts-left-col {
    order: 2;
  }
  .map-wrap {
    order: 1;
  }
  .contacts-info-mobile {
    display: block;
  }
  .contacts-info-desktop {
    display: none;
  }

}



@media (max-width: 370px) {
  .burger-wrap {
    padding: 0;
  }
}

