html {
	height: 100%;
	box-sizing: border-box;
}
html.popup {
    overflow: hidden;
}

*,*:before,*:after {box-sizing: inherit;}

body {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	min-width: 320px;
	font-family: @main-font;
	font-size: 18px;
	line-height: 1.3;
	font-weight: 300;
	min-height: 100%;
	background: url(../img/fon.jpg) 50% 0 no-repeat;
	background-attachment: fixed;
	color: @text-color;
}

.main-content {
	flex-grow: 1;
	margin-bottom: 50px;
}

/**
* Основной контейнер
*/

.container-wide {
	width: 100%;
	max-width: 1220px;
	padding-left: @grid-gutter-width / 2;
	padding-right: @grid-gutter-width / 2;
	margin-left: auto;
	margin-right: auto;
}
.container {
	width: 100%;
	max-width: 1024px;
	padding-left: @grid-gutter-width / 2;
	padding-right: @grid-gutter-width / 2;
	margin-left: auto;
	margin-right: auto;
}


/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button { cursor: pointer; }
address { font-style:normal; }
/**
* правила для плагина magnific popup
*/
html.no-scroll {
	overflow-y: hidden;
	padding-right: 15px;
}
.mfp-content {
	& .mfp-close {

    opacity: 1;

    & .icon {
      width: 25px;
      height: 25px;
      fill: @primary-color;
    }
  }
}

/**
* это иконки из svg-sprite
* указываем дефолтную ширину, высоту(для ие10)
*/
.icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	fill: #fff;
}

/**
* footer webmotor
*/
.webmotor {
	& span {
		color: #34081e;
		font-size: 13px;
		font-weight: 300;
	}
	& a {
		color: #34081e;
		text-decoration: underline;
		transition: 0.15s color ease-in;
		font-size: 13px;
		font-weight: 300;
	}
}

.page-head {
	margin-bottom: 20px;
}

p b {
	font-weight: 500;
	color: @dark-font;
}

p {
	max-width: 1060px;
}




